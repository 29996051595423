import {
  PharmacyOrderStatus,
  ActivityStatus,
  LoyaltyHistoryStatus,
  OrderStatus,
  LogisticDeliveryStatus,
  LogisticDeliveryStatusDetail,
  OrderPrecursorStatus,
} from 'services';
import {
  LoyaltyMissionActivityTypeCode,
  LoyaltyMissionStatus,
  LoyaltyRewardRedeemDeliveryStatus,
  LoyaltyTierName,
} from 'store/reducers';
import { Lang } from './types';

export const cartModal = {
  viewCart: 'View Cart',
  item: 'item',
  items: 'item',
};

const sectionCard = {
  seeAllAction: 'See All',
  recentlyViewed: 'Recently Viewed',
  close: 'Close',
};

const tabsProductDetail = {
  summary: 'Summary',
  details: 'Details',
  shipping: 'Shipping',
  recommendation: 'Recommendation',
};

const pharmacyTierInfo = {
  verifyAccount: 'Verify Account',
  learnMore: 'Learn more',
  unpurchasableInfo: 'This product is only available for verified account',
  message: 'Hello, I want to upgrade my account, please help.',
  missingSipnap: 'Please submit your SIPNAP license to purchase prekursor products',
};

export const recentlyViewedPageTitle = 'Recently Viewed';
export const recentlyPurchasePageTitle = 'Recently Purchased';

const noProducts = `We couldn't find the products your were looking for. Try searching for another one or contact our Customer Success team via {{callCenterNumber}} to request assistance.`;
const productListingSearch = 'Find {{productListingName}} products here…';

export const lang: Lang = {
  activationPage: {
    chatMessage: 'Hi, may I request activation?',
    contact: {
      clickButton: 'by pressing the button below.',
      customerService: 'Customer Service',
      please: 'Please contact mClinica',
    },
    request: 'Request for Activation',
    requestAccess: 'Request access',
    title: 'SwipeRx Beta',
  },
  app: {
    agree: 'I agree',
    seeAll: 'See all',
    appName: 'SwipeRx Procurement v{{version}}',
    bottomNav: {
      cart: 'Cart',
      home: 'Home',
      orders: 'Orders',
      payments: 'Payments',
      products: 'Products',
      account: 'Account',
      favorites: 'Favorites',
    },
    credit: {
      pay: 'Pay now',
      total: 'Your total credit line is',
      used: 'You have used {{amount}}',
    },
    dpd: {
      blockMessage:
        'Sorry, you cannot use SwipeRx services while the bill is outstanding. Please pay off {{pastDueInvoiceCount}} outstanding bills to use shopping services.',
    },
    suspended: {
      heading: 'Your account has been temporarily suspended',
    },
    orders: {
      used: 'Used Credit',
      total: 'Total Credit',
      availableCredit: 'Available Credit',
      timeLeft: 'Time Left',
      prekursorDocsRequired: 'Prekursor docs required',
    },
    decline: 'I do not agree',
    exit: 'Exit',
    helpSupport: 'Help and Support',
    notFound: 'Not found',
    notification: {
      close: 'Close',
      retry: 'Retry',
    },
    searchInput: 'Search 3000+ products here',
    tour: {
      start: 'Start Tour',
      gotIt: 'Got It!',
      welcomeTitle: 'Welcome to the new SwipeRx Procurement!',
      welcomeContent: "Let's begin your journey around the new features.",
      naviTitle: 'Navigation Tab',
      naviContent: 'Introducing the new navigation tabs',
      homeTitle: 'Home Tab',
      homeContent: 'Click here to go back to the home page',
      productTitle: 'Product Tab',
      productContent: 'Click here to view our entire product catalogue',
      orderTitle: 'Orders Tab',
      orderContent: 'Click here to see all of your orders',
      searchTitle: 'Search Bar',
      searchContent: 'You can search for products by their brand name or by their molecule',
      basketTitle: 'Basket',
      basketContent: 'Click here to see the items that you have selected',
      accountTitle: 'Account Tab',
      accountTitleContent: 'Click here to see the account detail',
      product: {
        productCardTitle: 'Product Detail',
        productCardContent:
          'All in-stock product now appears on top. For out of stock product, you can swipe your screen to the bottom.',
        productImageTitle: 'Product Image',
        productImageContent: 'You can also zoom in on a product by tapping on the image.',
        productFavoriteTitle: 'Favorite Button',
        productFavoriteContent:
          'Now you can mark your favorite product. Click on the heart button to mark a product as favorite.',
        productAddContent: 'Tap on "add" to add a product to your basket',
        productQuantityContent:
          'It is now easier to change the quantity of your product. Click the plus (+) button to increase, and the minus (-) button to decrease',
        viewCartTitle: 'View Cart',
        viewCartContent: 'Click this button to proceed on your order',
        quickFilterTitle: 'Quick Filter',
        quickFilterContent: 'Last purchased to see 3 products you recently ordered, and letter to filter by alphabet',
        appChatTitle: 'Customer Support',
        appChat: 'For additional questions, please contact our Customer Support team by clicking on this button.',
        searchProductByLetterContent: 'You can search for products name by letter',
      },
      tierDiscount: {
        title: 'Introducing bulk discounts',
        content: 'Now, it is easier than ever to get better discounts from bulk purchases.',
        expandBtn: 'To see which bulk discounts are available, simply click on this button.',
        discountBtn: 'To avail of a bulk discount, click on one of the options or change the quantity of the product.',
      },
      updates: {
        title: 'New updates!',
        content: 'We have recently introduced new features to make your app experience much better',
        basketTitle: 'Basket',
        basketContent: 'You now have a new basket! Click the icon to add a product and find out more',
        accountTitle: 'Account Tab',
        accountContent: 'We have added a new account tab where you can find details about credit, payment, and more',
      },
      wishlistLink: {
        title: 'Wishlist Menu',
        content: 'This will take you to wishlist page, you will find all products you have marked here!',
      },
      searchPage: {
        welcomeTitle: 'Search Page',
        welcomeMessage: 'This is the page where you can search for products before you order!',
        mostPopularTitle: 'Most Popular',
        mostPopularContent: `Now you can see what's the most popular product on SwipeRx!`,
        noResult: {
          welcomeTitle: 'Recommending a new product (1)',
          welcomeMessage: `You can now recommend a new product if you can't find the product you want to buy!`,
          suggestionTitle: 'Suggestion Button',
          suggestionContent: 'Tap on this button to suggest the product you want to recommend!',
        },
        packagingTitle: 'Find the type of packaging for the product you are looking for',
        packagingContent: 'Get the information under the product name',
      },
      wishlistPage: {
        welcomeTitle: 'Wishlist Page',
        welcomeMessage:
          'Welcome to wishlist page. Here you can find some tabs which contains all products you marked as favorite, remind me, and products you suggested to SwipeRx PharmaSource.',
        favoritesTabTitle: 'Favorite Tab',
        favoritesTabContent: `This is the favorite tab. You'll find products you've marked as favorite!`,
        remindersTabTitle: 'Remind Me tab',
        remindersTabContent: `This tab contains products you asked SwipeRx belanja to remind you of`,
        comingSoonTabTitle: 'Coming Soon Tab',
        comingSoonTabContent: `And this tab contains all products you've recommended to SwipeRx!`,
        askCsTitle: 'In-App Chat',
        askCsContent: 'You can ask our Customer Success for further question through in-app chat!',
      },
      favoritesPage: {
        removeFavoriteTitle: 'Favorite Button',
        removeFavoriteContent: 'You can remove a product from favorite by clicking the favorite button in this page',
      },
      remindersPage: {
        bellIconTitle: 'Click the bell icon to remove a product from the "Notify Me" list',
        bellIconContent: 'Organize the product list according to your needs',
      },
      productDetailPage: {
        linkToPageTitle: 'Click the product name on the main page to access the product page',
        linkToPageContent:
          'On the main page, click the product name to access a complete description of the product you want to order',
        welcomeTitle: 'Product Page',
        welcomeMessage: 'Welcome to Product Page!',
        promotionTitle: 'Get information about product discounts if the product has promotion',
        promotionContent: 'Find the discount according to the minimum purchase amount according to your needs.',
        descriptionTitle: 'Find a complete explanation of the product description',
        descriptionContent:
          'Click on the tab "description" to see a more complete description of the product you want to order',
        compositionTitle: 'Find a description of the composition of the product',
        compositionContent:
          'Click on the tab "composition" to see more complete content of the product that you will order',
        coreMoleculeTitle: 'Find a description of the core molecule of the product',
        coreMoleculeContent:
          'Click on the tab "core molecule" to see more complete content of the product that you will order',
        categoryTitle: 'Get information related to product category',
        categoryContent: 'Check product categories by clicking on "category"',
        atcTitle: 'Get information related to product atc classification',
        atcContent: 'Check product atc classification by clicking on "atc-classification"',
        manufacturerTitle: 'Get information related to product the manufacturer"',
        manufacturerContent: 'Check product manufacturer by clicking on "manufacturer"',
        shippingTitle: 'Find information regarding shipping and returns policies',
        shippingContent: 'Complete information about the process of sending and returning goods',
        tierTitle: 'Choose the minimum purchase according to the discount you want',
        tierContent: 'Click on the minimum amount you want',
        ...tabsProductDetail,
      },
      homepage: {
        welcomeTitle: 'Welcome, to our new homepage experience!',
        welcomeMessage: `Now, It's easier to fulfill your pharmacy needs with a more attractive and intuitive interface`,
        loyaltyTitle: 'Click here to access your SwipeRx Reward Pass',
        loyaltyContent: 'Collect your points and redeem points with attractive prizes from us',
        mainBannerTitle: 'Find latest update and promotions here',
        mainBannerContent: 'Get the latest informations and interesting promotions from us here',
        benefitsTitle: 'The benefits of shopping at SwipeRx',
        benefitsContent: 'SwipeRx Belanja, the number 1 pharmacy procurement application',
        newProductsTitle: 'Find the latest products available in our app here',
        newProductsContent: 'Search and order the product you need',
        topWeeklyProductsTitle: 'Find the top selling products every week here',
        topWeeklyProductsContent: 'Every Monday we feature new products based on demand',
        campaignListTitle: 'Get latest promotions from us',
        campaignListContent: 'Find out every month attractive promotions and prizes here',
        hotDealsTitle: 'Get the best prices we can offer to you',
        hotDealsContent: 'We negotiate best deals with all PBFs so you save money with us',
        featuredProductsTitle: 'Shop products specially selected for you',
        featuredProductsContent: 'Find out seasonal products or selected products designed to your needs',
        recentlyViewedTitle: 'Rediscover the products you just review',
        recentlyViewedContent:
          'To ease your purchasing experience, you can always access to the products you reviewed before',
      },
      loyaltyLandingPage: {
        welcomeTitle: 'Welcome to the new Loyalty Landing Page!',
        welcomeMessage:
          'Now, Membership Details, Tier Information, and Rewards can be easily seen with a more intuitive and interactive interface',
        membershipDetailTitle: 'See your Membership Details',
        membershipDetailMessage: 'Click to view Membership Details and Points Information',
        activePointsTitle: 'See your Active Points',
        activePointsMessage: 'See points needed to get to unlock next level',
        mostPopularRewardsTitle: 'See Most Popular Rewards',
        mostPopularRewardsMessage: 'Click See All to view top 50 popular rewards',
        howToGetPointsTitle: 'See how you can get points',
        howToGetPointsMessage: 'Know how you can get points by doing things listed',
        allRewardsTitle: 'See All Rewards',
        allRewardsMessage: 'Click See All to view all rewards with more customized filters',
        missionWelcomeTitle: 'Welcome to our new Mission!',
        missionWelcomeMessage:
          'Get more rewards by completing your mission. You can always track your progress on this page. Complete your Mission now and claim your rewards!',
        featuredMissionsTitle: 'See your Featured Missions',
        featuredMissionsMessage: 'Click See All to view all missions',
      },
      loyaltyMembershipPage: {
        welcomeTitle: 'Welcome the to new Loyalty Membership Page!',
        welcomeMessage: 'You can see here Membership Information and Benefits, Point Information, and Redeemed Rewards',
        membershipDetailTitle: 'See your Membership Details',
        membershipDetailMessage: 'See how many points needed to retain your membership',
        pointSummaryTitle: 'Point Summary now moved to Membership Page',
        pointSummaryMessage: 'See your points and its distribution',
        myRewardsTitle: 'See your redeemed rewards',
        myRewardsMessage: 'Click See All to view all redeemede rewards',
        membershipBenefitsTitle: 'Membership Benefits',
        membershipBenefitsMessage: 'See benefits you can enjoy in your current tier',
      },
      loyaltyHotRewardPage: {
        welcomeTitle: 'Welcome to the new Hot Rewards Page!',
        welcomeMessage: 'Now, filtering and searching can be utilized to easily look for rewards.',
        searchTitle: 'New Reward Search',
        searchMessage: 'You can search specific reward based on its name',
        pointsRequiredTitle: 'New Points Required Filter',
        pointsRequiredMessage: 'You can filter rewards based on the minimum and maximum points needed',
        vendorTitle: 'New Vendor Filter',
        vendorMessage: 'You can filter rewards based on the vendor',
        categoryTitle: 'New Category Filter',
        categoryMessage: 'You can now filter rewards based on its category',
      },
      loyaltyMyRewardPage: {
        welcomeTitle: 'Welcome to the new My Rewards Page!',
        welcomeMessage: 'Now, searching can be utilized to easily looked for redeemed rewards',
        searchTitle: 'New My Reward Search',
        searchMessage: 'You can search redeemed reward based on its name',
      },
      loyaltyMissionCataloguePage: {
        [LoyaltyMissionStatus.ACTIVE]: {
          welcomeTitle: 'Welcome to the Mission Catalogue Page',
          welcomeMessage: 'This is the welcome message of active missions',
          missionTitle: 'This is the Title of Active Mission List',
          missionMessage: 'This is the message of active mission list',
        },
        [LoyaltyMissionStatus.COMPLETED]: {
          welcomeTitle: 'Welcome to the Mission Catalogue Page',
          welcomeMessage: 'This is the welcome message of completed missions',
          missionTitle: 'This is the Title of Completed Mission List',
          missionMessage: 'This is the message of completed mission list',
        },
        [LoyaltyMissionStatus.EXPIRED]: {
          welcomeTitle: 'Welcome to the Mission Catalogue Page',
          welcomeMessage: 'This is the welcome message of expired missions',
          missionTitle: 'This is the Title of Expired Mission List',
          missionMessage: 'This is the message of expired mission list',
        },
      },
      loyaltyMissionPage: {
        welcomeTitle: 'Welcome to the Mission Page',
        welcomeMessage: 'This is the welcome message',
      },
      productListingPage: {
        filterModalTitle: 'Filter Menu',
        filterModalContent: 'This menu will appear after you click the Sort & Filter button',
        filterToolbarTitle: 'We have updated the filter options',
        filterToolbarContent:
          'Aside from alphabetical filters, we have added more options to tailor your search results.',
        filterButtonTitle: 'Change order filter options',
        filterButtonContent: 'Click here again to change the order and filter options.',
      },
    },
    cartModal,
  },
  tierDiscount: {
    getUpTo: 'Get up to {{discount}}% discount',
    buyMinimum: 'Buy minimum of {{quantity}}',
    getDiscount: 'Get {{discount}}% discount',
    promoApplied: '{{discount}}% discount has been applied!',
  },
  tierDiscountSheet: {
    getUpTo: 'Get up to {{discount}}% Discount',
    getDiscount: 'Discount {{discount}}%',
    buyMinimum: '(Buy min.{{quantity}})',
    buy: 'Buy',
  },
  stockOut: {
    outOfStock: 'Out of stock',
    lowOnStock: 'Less than {{stockLeft}} units left in stock!',
    maxQtyReached: 'Maximum quantity reached',
    notify: 'Remind Me',
    cardTitle: 'Sorry, the item is currently out of stock.',
    cardDescription: "Click 'Remind Me' to get notified once stock becomes available.",
    dialogTitle: 'We have received your request!',
    dialogDescription: 'Thank you for your patience! We will remind you once the item becomes available.',
    okay: 'Okay',
    reminderRemoval: 'Will be removed from "Reminders" page on {{datetime}}. Buy now!',
    maxDailyQtyLimit: 'MAX {{value}}/DAY',
    maxMonthlyQtyLimit: 'MAX {{value}}/MONTH',
    maxQtyLimitReached: 'Maximum quantity reached, please place order up to {{ availableQty }} unit(s) today.',
    maxQtyMonthlyLimitReached: `You will reach {{value}} maximum monthly limit for this product after checkout. To do more purchasing after checkout, you may purchase it again next month or reach out to our Customer Service team to get further service`,
  },
  cartPage: {
    cartItems: 'There are {{itemCount}} items in your cart.',
    getPrices: 'Get Prices',
    noItems: 'There are currently no items in your cart.',
    oneItem: 'There is 1 item in your cart.',
    receivePrices: 'Instantly receive the best prices for these products',
    requestPrices: 'Request prices for the best deal',
    search: 'Search for products',
    yourCart: 'Your Cart',
    unavailableProduct: "Some of your items from your cart were removed as they're no longer available.",
    viewCart: 'View Cart',
  },
  errorPage: {
    closeApp: 'Close the App',
    contactMessage:
      'If you need additional assistance, please feel free to contact us via email at beta@swiperxapp.com',
    contactUs: 'Contact us',
    error: 'Error: $t(errors:{{message}})',
    pressingButton: 'or by pressing the button below',
    sorryMessage: 'Sorry, there was a problem',
    tryAgain: 'Try Again',
  },
  errors: {
    'Activation request has already been approved': 'Activation request has already been approved',
    'Activation request has already been submitted': 'Activation request has already been submitted',
    'Could not send activation request email': 'Could not send activation request email',
    'No active bank account has been set up for the user': 'No active bank account has been set up for the user',
    'Order does not exist': 'Order does not exist',
    'Organization id or name must be provided': 'Organization id or name must be provided',
    'Organization not found': 'Organization not found',
    'products parameter must be provided': 'products parameter must be provided',
    'Request timed out': 'Request timed out',
    'Session does not exist': 'Session does not exist',
    'Successfully requested for activation!': 'Successfully requested for activation!',
    'Search query must be provided': 'Search query must be provided',
    'Successfully updated cart!': 'Successfully updated cart!',
    'The net amount after tax exceeds your remaining balance':
      'The net amount after tax exceeds your remaining balance',
    'User has not yet requested for activation': 'User has not yet requested for activation',
    'User account has not yet been activated': 'User account has not yet been activated',
    'You do not have access to this order': 'You do not have access to this order',
    'Network Problem': 'Network error or interruption occured.',
  },
  helpPage: {
    chatMessage: 'Hi, I need help on ',
    chatWithUs: 'Chat with us!',
    contactUs: 'Click here to contact us',
    gladToHelp: 'Our support team is here to help you order the right products on time.',
    helpOrdering: 'Need help ordering?',
    title: 'Help and Support',
  },
  creditLimitRequest: {
    title1: 'Request',
    title2: 'Credit Limit',
    step: 'Steps',
    step1: 'Please fill out the following form and upload the requested documents',
    step2: 'Our team will then review the credit limit request',
    currentCreditLimit: 'Your current credit limit:',
    inputYourNewCreditLimit: 'Enter your new credit limit',
    termsAndCondition: 'By clicking CONTINUE, you agree with the terms and condition.',
    continue: 'CONTINUE',
    formLinkLabel: 'Form Link',
    overLimitMessage:
      'Sorry, the credit limit that you are applying for has exceeded SwipeRx application allowed limit. For more info please contact our Customer Support',
    supportLinkMessage:
      "Hi i'm {{userName}}, requesting credit limit upgrade {{pharmacyName}} pharamacy in the amount of {{creditLimit}}",
    supportLinkLabel: 'link to WA',
    errorRequestLimit: 'Your request is below than your current credit limit',
  },
  creditLimitRequestStatus: {
    appBarTitle: 'Request Credit Limit',
    requestInProgress: 'Your request is being processed',
    requestInProgressSubtitle: 'Our credit team is still reviewing your request and will inform you afterward.',
    requestAccepted: 'Congratulation!\nYour request is accepted.',
    newCreditLimit: 'Your credit limit is updated into {{newCreditLimit}}',
    requestRejected: 'Kindly apologize we are not able to proceed with your request',
    reason: "Here's why",
    approvalNotes: 'Notes',
    requestAgain: 'Request Again',
    thankYou: 'Thank you for completing the form.',
    willInform: 'We will notify you once we have an update on your credit limit request around 2-3 business days',
    checkProgress: 'check progress',
  },
  increaseCreditPage: {
    apply: 'Apply for a bigger credit limit',
    chatMessage: 'Hi, may I request for a bigger credit limit?',
    orderLimit: 'Your total order limit',
    text: {
      line1:
        'You can automatically increase your Credit Limit to Rp 20,000,000 by transacting and paying your outstanding invoices on time. Just follow these 3 easy steps.',
      line2: 'Transact at least Rp 8,000,000 per month for 3 months',
      line3: 'Pay your outstanding invoice within 37 days from the invoice date of 3 months',
      line4:
        'We will then automatically increase your limit to IDR 20,000,000 and contact you to notify when your limit has been increased',
      line5:
        'Apply for a custom Credit Limit of up to Rp 500,000,000 from Modalku by submitting your bank statements for at least 3 months. Contact our customer service (beta@swiperxapp.com) during work hours and we will help you with the application.',
    },
    title: 'Increase your credit limit',
  },
  orderHeader: {
    deliveryDate: 'Delivery Date',
    invoiceDate: 'Invoice Date',
    invoiceNumber: 'Invoice Number',
    orderDate: 'Order Date',
    orderNumber: 'Order Number',
    paymentDueDate: 'Payment Due Date',
    status: {
      pending: 'pending',
      accepted: 'accepted',
      delivered: 'delivered',
      completed: 'completed',
    },
  },
  orderPage: {
    completeOrder: 'Your Complete Order',
    deliveryProof: 'Proof of Delivery',
    discount: 'discount',
    documents: 'Documents',
    invoice: 'Invoice',
    viewTaxInvoice: 'View Tax Invoice',
    noTaxInvoice: 'Tax Invoice Document not yet submitted or approved.',
    orderDetail: 'Order Details',
    products: 'Products',
    subtotal: 'Subtotal',
    subtotalDiscount: 'Subtotal after Discount',
    totalAmount: 'Total Order Amount',
    totalAmountAfterVat: {
      total: 'Total Amount',
      vat: '(Tax may be included)',
    },
    unit: 'unit',
    units: 'units',
    vatAmount: 'VAT ({{tax}})',
    prekursor: {
      title: 'Prekursor Document',
      add: 'Add Prekursor Document',
      uploadSuccess: {
        title: 'Yay! your precursor document image uploaded succesfully',
        info: 'You can check status of this order in Activities tab',
        action: 'OKAY',
      },
      uploadFailed: {
        title: 'Oops! The upload of your precursor document failed',
        info: 'failed file:',
        action: 'RETRY',
      },
      uploadApprovedFailed: {
        title: 'Document already approved, can not update document',
        info: 'failed file:',
        action: 'RETRY',
      },
      prekursorTooltipTour: {
        preUpload: 'Click here to upload Prekursor document',
        postUpload: 'Tap here to preview or edit Prekursor document',
        editUpload: 'Tap here to edit Prekursor document image',
      },
    },
  },
  ordersPage: {
    schedulePayment: 'Schedule Payment',
    deliveryDue: 'Delivery Due',
    history: 'History',
    increaseCreditLimit: 'Increase your total credit limit',
    learnMore: 'Learn more',
    orderDate: 'Order Date',
    orderNumber: 'Order Number',
    paymentDue: 'Payment Due',
    paymentDueDate: 'Payment Due Date',
    howToPay: 'How to Pay',
    contactNow: `Please contact Customer Success via {{callCenterNumber}} if you have any questions`,
    totalAfterVat: {
      afterVat: '(Tax may be included)',
      total: 'Total Amount',
    },
    viewDetails: 'View Details',
    status: {
      pending: 'pending',
      accepted: 'accepted',
      delivered: 'delivered',
      completed: 'completed',
    },
    goToSearch: 'Go to search',
    pendingPayments: 'Pending Payments',
    daysPastDue: 'days past due',
    Due: 'DUE',
    invoiceNotYetIssued: 'IN PROGRESS',
    orderID: 'Order No.',
    noPaymentsDueToday: 'No payments due today',
    payEarly: 'You can pay early to get your credit limit back',
    selectAnotherDate: 'Select another date to check for due payments',
    overdueInvoices: 'You have overdue invoices. Click on the button to see how much you need to pay',
    noOrders: 'No orders at the moment',
    serchForAProduct: 'Search for a product to start purchasing.',
    totalDueAmount: 'Total Amount Due',
    noPaymentDueBy: 'No Payment Due By',
    recentOrders: 'Recent Orders',
    viewAll: 'View all',
    noRecentOrders: 'No orders at the moment',
    youHaveNoTransactions: 'Search for a product to start purchasing.',
    viewCompleteHistory: 'View your complete order history',
    COH: 'Complete Order History',
    back: 'Back',
    loading: 'Loading...',
    done: 'Done',
    paidOn: 'Paid on:',
    selectDate: "Select the date you'd like to pay and see how much you will owe.",
    bankTransfer: 'Bank Transfer',
    howToPayDetails: 'You may pay in advance using the bank details below.',
    transfer: 'Transfer',
    on: 'on',
    toThisBankAccount: 'to this bank account',
    bank: 'Bank',
    accNum: 'Nomor Account',
    accName: 'Name',
    cashPay: 'Cash on pharmacy',
    prepare: 'Prepare',
    cashInPharmacy: 'in cash in your pharmacy and schedule a pick up with by contacting our Customer Success below',
    selectDateSchedule: 'Select Date',
    endOfPage: 'You have reached the end of this page.',
    selectDueDateHead: 'Select Due Date',
    checkThisDate: 'check This Date',
    paidOnTime: 'Paid on',
    partialPayment: 'Processing payment',
  },
  payPage: {
    bank: {
      accountNumber: 'Account Number',
      label: 'Bank',
      name: 'Name',
    },
    contactNow: `Please contact Customer Success via {{callCenterNumber}} if you have any questions`,
    contactService: 'Contact our customer service now to arrange a cash pick up.',
    dueAmount: 'Total amount due',
    method1: 'Method 1: Bank Transfer',
    method2: 'Method 2: Cash Pickup at Pharmacy',
    noBankAcc: 'No bank details have been set up for this account yet. Please contact customer service.',
    howToPay: 'How to Pay',
    title: 'Pay Now',
    totalAmountOrdered: 'Total amount ordered',
    dueNow: 'Due now',
    orderNumber: 'Order Number',
    orderDate: 'Order Date',
    vat: 'After VAT',
    dueWeek: 'Due within a week',
    due: 'Due',
  },
  paymentStatus: {
    due: {
      label: 'Due',
      message: 'Pay early and increase your order limit',
    },
    dueSoon: {
      label: 'Due soon',
      message: 'Pay early and increase your order limit',
    },
    overDue: {
      label: 'Invoice Due',
      message: 'If you have already paid, disregard this message',
    },
  },
  pricesPage: {
    yourAccountWasDowngraded: 'Oops! Your account status was downgraded.',
    downgradedAccountAction: 'Please provide the new license to buy this product',
    verifyAccount: 'Verify your account',
    acceptBuy: 'Accept and Buy',
    agree: 'I agree',
    availableCredit: 'Available Credit',
    bestPrices: 'We found the best PBF prices',
    completeOrder: 'Your Complete Order',
    continue: 'Continue',
    creditAfterOrder: 'Credit after order',
    creditLine: 'Credit Line',
    creditForOrder: 'Credit for this order (after VAT)',
    decline: 'I do not agree',
    deliveryTerms: 'Terms of Delivery',
    distributor: 'Distributor',
    manyCreatedOrders: 'Your order numbers are',
    goBack: 'Go back',
    purchase: 'Purchase',
    confirmation: 'Are you sure you want to proceed?',
    goodNews: 'Good News!',
    helloBoss: 'Hello!',
    prekursorDocumentRequired: 'You will have to upload an Prekursor document after order confirmation',
    readyToReceiveOrders: 'Our distributors are now ready to receive your orders.',
    tryToFixTheFollowingOrders: 'It appears we have some problems with your order. Let’s try to fix the following.',
    fullfillOrders: 'to fulfill your orders!',
    weFound: "We've found ",
    yourOrder: 'Your order',
    below: 'is below',
    orders: 'orders',
    cartTotal: 'You have {{total}} items in your basket.',
    needsToBeAdjusted: 'Your <0>order</0> needs to be adjusted.',
    ordersReadyToServed: 'Your orders are ready',
    paymentDate: 'Make payment on {{date}}',
    subtotal: 'Subtotal',
    addMoreProduct: 'Add more products',
    minimumInvoice: 'Your total order is below the minimum invoice of\n<0>{{amount}}<0>.',
    minimumInvoiceDesc: 'Please add the quantity, or get the item(s) in your basket',
    notEnoughCredit: "Oops! You don't have enough credit for your order.",
    enoughCredit: 'You have enough <0>credit<0> for Your order.',
    orderTotal: 'Order Total',
    totalDiscount: 'You have saved {{amount}}!',
    totalOrderBelowMinimum: {
      itSeems: 'Hmm, it seems that your total order for',
      belowMinimum: 'is below the minimum invoice value',
    },
    howCanIProceed: 'How Can I proceed?',
    clickHereToProceed: 'Click here to proceed',
    proceedWithOrder: 'Proceed with the order',
    noAlternativeProductsFound: 'No alternative products found at the moment.',
    close: 'Close',
    orderSummary: 'Order Summary',
    eVat: 'VAT',
    totalAfterVat: 'Total Amount (After VAT)',
    includesVat: 'Includes VAT {{ taxRate }}',
    paymentSummary: 'Payment Summary',
    creditForThisOrder: 'Credit for this order (after VAT)',
    notEnoughCreditForOrder: 'Sorry, you do not have enough credit for the order.',
    notEnoughCreditForOrderSubtitle: 'Please pay the credit first',
    payCredit: 'Make a payment',
    looksLikeNotEnoughCredit: 'It looks like you do not have enough credit for this order.',
    looksLikeBelowMinimumInvoiceAmount: 'It looks like you are below the minimum invoice value.',
    letsTryTheFollowingOptions: "Let's try the following option.",
    reduceProductQuantity: 'Reduce the quantity of some products',
    increaseProductQuantity: 'Increase the quantity of some products',
    cancelDistributorOrder: 'Cancel the order for {{ distributor }}',
    cancelOrder: 'Cancel Order',
    confirmCancelOrder: 'Are you sure you want to cancel the order for this distributor?',
    thisWillRemoveProductsBelow:
      'This will only remove the products below. Other products will still be fulfilled by other distributors',
    otherDistributorsOfferingSameProduct:
      'Other distributors are offering the same products with slightly higher prices.',
    transferSomeProductsBelow: 'Let’s transfer some of the products below.',
    weHaveCancelledYourOrder: 'We have cancelled the order for this distributor',
    proceedToYourBasket: 'Proceed to your basket',
    transferProducts: 'Transfer Products',
    currentlyLookingForAlternatives: 'We are currently looking for alternative distributors, please wait..',
    transferAllProducts: 'Transfer All Products',
    transferProductsToAnotherDistributor: 'Transfer products to another distributor',
    productMoved: 'This product has been moved because it is no longer available in the other distributor.',
    productMovedDueToPriceDecrease:
      'This product has been moved to another distributor as we found a better alternative',
    confirmOrder: 'Confirm Order',
    waitAMinute: 'Hmm, Wait a minute...',
    forAssistance: 'For additional assistance, please contact our',
    customerSupportViaWhatsApp: `Customer Success via {{callCenterNumber}}.`,
    goBackAndCheck: 'Go back and check',
    ok: 'Ok',
    orderNow: 'Continue {{ totalItem }}',
    earnPoints: 'Earn <0>{{ points }}</0>',
    proceedPurchase: 'Would you like to proceed with your purchase?',
    submitOrderTo: 'This will submit orders to',
    pleaseUploadPrekursorDocument: `And please upload pending prekursor document tab after confirm this order`,
    reason: 'Reason:',
    productNoLongerAvailable: 'This product is no longer available at the moment.',
    membershipChanged:
      'Your account status just got downgraded,  Please submit your renewed license to buy this product',
    sipnapLicenseRequired: 'Product needs sipnap license to be bought',
    maxQtyLimitReached: `You've reached the maximum unit(s) allowed for the purchase of the product. It cannot be purchased again until tomorrow.`,
    maxQtyMonthlyLimitReached: `You will reach {{value}} maximum monthly limit for this product after checkout. To do more purchasing after checkout, you may purchase it again next month or reach out to our Customer Service team to get further service`,
    priceIncrease: 'The price has increased since your last checkout!',
    priceDecrease: 'The price has been reduced from {{ price }}!',
    tour: {
      start: 'Start Tour',
      welcomeTitle: 'Welcome to SwipeRx Belanja!',
      welcomeContent: `Let's check out our latest feature update!`,
      overviewTitle: 'Basket Overview',
      overviewContent: 'This is where you can find information about the products you want to order.',
      productTitle: 'Products',
      productContent: 'In this section, you get to see all of the products that you added',
      orderSummaryTitle: 'Order Summary',
      orderSummaryContent: 'Here is the total amount for your order.',
      paymentSummaryTitle: 'Payment Summary',
      paymentSummaryContent: 'Find out if you have enough credit for this order in this section.',
      deliveryTermsTitle: 'Terms of Delivery',
      deliveryTermsContent: 'Check out the terms of delivery for your order.',
      orderNowTitle: 'Order Now',
      orderNowContent: 'If everything looks good, click on “Order Now”',
      product: {
        productCardTitle: 'Product Card',
        productCardContent: 'You can now see price information on the new product card!',
        productImageTitle: 'Product Image',
        productImageContent: 'You can also zoom in on a product by tapping on the image.',
        productAddContent: 'Tap on "add" to add a product to your basket',
        productQuantityContent:
          'It is now easier to change the quantity of your product. Click the plus (+) button to increase, and the minus (-) button to decrease',
        viewCartTitle: 'View Cart',
        viewCartContent: 'Click this button to proceed on your order',
        searchProductByLetterContent: 'You can search for products name by letter',
      },
    },
    minValue: {
      heading: 'Your order does not fulfill the minimum order value',
      increase: 'Please increase your total order or add more products.',
      minValue: 'Your order has to be at least {{minAmount}}.',
    },

    noEnoughCredit: {
      title: "Oops! You don't have enough credit for your order",
      subTitle: 'Please make a payment to your credit first or check your basket',
      actionButton: 'Make a payment',
      cancel: 'Cancel',
    },
    belowMinimum: {
      title: 'Oops! You need to recheck your basket',
      subTitle: 'Your total order is below the minimum invoice value',
      actionButton: 'Go back to my basket',
    },
    suspended: {
      heading: 'Your account has been temporarily suspended',
      reason: 'Reason for Suspension',
    },
    dpd: {
      blockMessage:
        'It seems like you are currently blocked from transacting because you have {{pastDueInvoiceCount}} invoices overdue for {{dpd}} days.',
    },
    voucher: {
      info: 'You can enter the Voucher Code after confirming your purchase of the product',
      chooseVoucher: 'Apply a voucher',
      voucherApplied: 'Voucher worth {{amount}} applied as payment',
    },
    oops: 'Oops!',
    encounteredAnErrorWithYourOrder: 'It seems we encountered an error with your order',
    productsChangedAvailability:
      'There are products that have experienced changes in price and availability. Please check again.',
    orderPaymentError: 'We encountered an error with the payment provider. Please try again later',
    oneCreatedOrder: 'Your order number is',
    oneDistributor: '1 distributor is serving you',
    products: 'Products',
    items: 'items',
    item: 'item',
    orderFor: 'Order For',
    searchMore: 'Search more',
    servingDistributors: '{{distributorCount}} distributors are serving you',
    success: "We've successfully submitted your order!",
    youHaveNoProductOnCart: 'You have no products on your cart',
    timeToCreateAnOrder: "It's time to create an order!",
    firstProductsText: 'The first step to finding the best prices is finding the products you need.',
    searchProducts: 'Search Products',
    title: 'My Basket',
    totalAmount: {
      total: 'Total Amount',
      vat: 'after VAT',
    },
    totalCreditLimit: 'Total credit limit',
    totalOrderAmount: 'Total Order Amount',
    vat: 'VAT ({{amount}})',
    weFoundBestPrices: 'Yay, we found the best PBF prices!',
    payment: {
      heading: { id: 'Payment', th: 'Payment' },
      terms: { id: '30 days payment terms', th: '30 days payment terms' },
    },
    delivery: {
      heading: { id: 'Delivery', th: 'Shipping' },
      deliveryHours: {
        id: 'Delivery within 24 hours',
        th: 'Ordering Before 10.00AM, we will provide same day delivery.',
      },
      conditions: {
        id: 'Same day delivery is available for any order comes in before 10.am exclude Sunday, and National Public Holiday.',
        th: 'Ordering After 10.00AM, we will provide next business day delivery.',
      },
      conditions2: {
        id: 'Any order comes in after 10 AM will be processed the day after.',
        th: 'Delivery Time is 2.00PM - 6.00PM',
      },
      terms: {
        id: "Product will be delivered between 5 PM to 10 PM once the PBF has processed the PO. If you're not available to receive your order, please inform us and we can re-arrange the schedule for you.",
        th: '(No Delivery Service on Weekend and Public Holiday)',
      },
    },
    return: {
      heading: { id: 'Return', th: 'Return' },
      conditions: {
        id: 'Return policy on a per company basis',
        th: 'You have 1 business day from the shipping date of your order to return your purchase from SwipeRx free of charge.\n\nThese are the options for returning your item(s):\n1. Return at the point of delivery\n2. Contact customer support.\nLine: @swiperx_th\n\nSpecial Conditions\n\n1. Some of our items will not be able to returned\n2. Returns after 1 day from the shipping date of your order are subject to distributor policy',
      },
    },
    contact: {
      heading: { id: 'Customer Service', th: 'Customer Service' },
      terms: { id: 'You can request tax invoice here', th: 'You can request tax invoice here' },
      conditions: {
        id: 'You can request tax invoice here',
        th: 'You can request tax invoice here',
      },
      line: { id: 'Line : @swiperx_th', th: 'Line : @swiperx_th' },
      phone: { id: '+66-60-003-5146', th: '+66-60-003-5146' },
      officeHour: { id: 'Mon - Fri/ 9.00-18.00', th: 'Mon - Fri/ 9.00-18.00' },
    },
    unavailableProduct: "Some of your items from your cart were removed as they're no longer available.",
    lowOnStock: 'Less than {{stockLeft}} units left in stock!',
    maxQtyReached: 'Maximum quantity reached',
    outOfStock: 'Out of stock',
    notify: 'Remind Me',
    unnotify: 'Cancel Reminder',
    unpaidOrder: 'Unpaid Order',
    limitedPaymentInfo: 'You still have an unpaid order. Kindly pay your previous order.',
    clearCart: {
      title: 'Are you sure?',
      content: 'Are you sure you want to delete {{itemCount}} product(s) from basket?',
      no: 'No',
      delete: 'Delete',
    },
    addedToFavorite: 'Added to wishlist',
    removedFromFavorite: 'Removed from wishlist',
    selectAll: 'Select all',
    getProductSoon: 'Get the products soon',
    seeDeliveryEstimation: 'Get products on <0>{{ estimationDate }}</0>',
    checkoutBeforeCutoff: 'Transact before cut-off time {{cutoffTime}}to receive the products by delivery date',
    prekursorDeliveryCondition:
      'To ensure the products are delivered by the same estimated delivery date, kindly upload the prekursor documents.',
    prekursorDelivery: 'Pending prekursor',
    instruction: 'Instruction',
    deleteConfirmation: {
      prefix: 'Are you sure you want to delete',
      label: 'the\n selected items',
      suffix: 'from your basket?',
      cancel: 'cancel',
      confirm: 'delete',
    },
    cartChangedConfirmation: {
      title: 'Oops! You need to recheck your basket',
      subtitle: 'Your order needs to be adjusted.',
      close: 'Go Back to My Basket',
    },
    maxDailyQtyLimit: 'MAX {{value}}/DAY',
    maxMonthlyQtyLimit: 'MAX {{value}}/MONTH',
    maxPurchaseLimitReached: {
      alert: {
        titleOneProduct: `Oops! You've reached the maximum unit(s) allowed for the purchase of the {{ productName }}`,
        titleManyProducts: `Oops, You’ve reached the maximum unit(s) allowed for the purchase of some products`,
        adjustQty: 'Please adjust the product quantity',
        changeProductQty: 'change product quantity',
        cancel: 'Cancel',
      },
      summary: {
        maxPurchaseLimitReached: "You've reached the maximum unit(s) allowed for the purchase of the item.",
        pleaseAdjustQtyOneProduct: 'Please adjust the {{ productName }} quantity below',
        pleaseAdjustQtyManyProducts: 'Please adjust the products quantity below',
        changeProductQty: 'change product quantity',
      },
    },
    findSimilarProduct: 'Find Similar',
    remindMeModalTitle: 'Product reminder has been activated!',
    remindMeModalBody: 'We will let you know when the product becomes available again.',
    okay: 'Okay',
  },
  requestingPage: {
    checking: 'We are checking with PBFs for the best price for you today. This may take a few seconds.',
    findingProducts: 'Finding the best prices for you',
  },
  searchPage: {
    addProduct: 'Add a Product',
    addToCart: 'Add to cart',
    alreadyOrdered: "You've ordered this before",
    buy: 'Buy',
    buyAgain: 'Buy again',
    cartItems: 'There are {{itemCount}} items in your cart.',
    checkout: 'Check out',
    noItems: 'There are currently no items in your cart.',
    noProducts,
    oneItem: 'There is 1 item in your cart.',
    packaging: 'Packaging',
    quantity: 'Quantity',
    recentPurchases: 'Your most recent purchases',
    searchPlaceholder: 'Search 3000+ products here',
    searchResults: 'Search results',
    productIsUnavailable: 'Product is currently unavailable',
    notifyMe: 'Notify me when available',
    notifyInfo: 'You will receive an email notification one the product is available again',
    getBackToYouSoon: "We'll get back to you soon!",
    availableProducts: 'Available Products',
    all: 'All',
    jumpLetter: 'Jump to A Letter',
    back: 'Back',
    recentlyPurchased: recentlyPurchasePageTitle,
    lastPurchased: 'Last Purchased',
    recentlySearched: 'Recently Searched',
    pageEnd: 'You have reached the end of this page. Check back later for more.',
    default: 'To search for products, type in the brand, molecule or company, or you can',
    browse: 'browse through our product catalogue',
    mostPopular: 'Most Popular',
    campaignBased: 'Most Popular',
    products: 'Products',
    manufacturer: 'Manufacturer',
    didYouMean: 'Did you mean',
    relatedProducts: 'Related Products',
    seeAllRelated: 'See all {{count}} related products',
    seeAll: 'See All',
    cartModal,
    filterSort: {
      filterSort: 'Sort & Filter',
      filter: 'Filter',
      sort: 'Sort',
      sortBy: 'Sort by',
      loyaltyExtraPoint: 'Loyalty extra point',
      discount: 'Discount',
      min: 'Min',
      max: 'Max',
      to: 'to',
      discountError: 'required to input value for both min and max',
      mostPurchased: 'Most Purchased',
      lowestPrice: 'Lowest Price',
      highestDiscount: 'Highest Discount',
      latest: 'Latest',
      mostSeen: 'Most Seen',
      reset: 'Reset',
      apply: 'Apply',
    },
    ...sectionCard,
  },
  orderCard: {
    orderNumber: 'Order No.',
    invoiceDate: 'Invoice Date',
    dueDate: 'Due Date',
    paymentDate: 'Payment Date',
    invoices: 'Invoices',
    deadline: 'Deadline',
    totalInvoice: 'Invoice Amount',
    paid: 'paid',
    paidAmount: 'Paid Amount',
    outstandingAmount: 'Outstanding Amount',
    partiallyPaid: 'Partially Paid',
    totalAmount: 'Total Amount',
    creditNote: 'Credit Note Applied',
  },
  orderStatus: {
    partial: 'Partially Paid',
    paid: 'Paid',
  },
  payments: {
    noAvailableInvoice: 'No available invoice to display',
    noAvailablePaidInvoice: 'No available paid invoice to display',
    payFirst: 'You do not have any paid invoices, pay them first!',
    letsMakeOrder: 'How about making a few orders with us?',
    orderNow: 'Order Now',
    goToUnpaid: 'UNPAID',
    goToPayment: 'PAYMENT',
    totalAmount: 'Total Amount',
    invoiceSelection: 'Invoice Bill',
    invoiceSelections: 'Invoice Bills',
    allSelection: 'All',
    selectPaymentOption: 'Please select your payment option below',
    paymentOption: 'Payment Option',
    payAnyAmount: 'Pay Any Amount',
    totalDueInvoice: 'Total Due Invoice',
    selectedInvoice: 'Selected Invoice',
    payWithVoucher: 'Pay Using A Voucher',
    voucherApplied: '{{amount}} Discount applied!',
    continue: 'continue',
    payments: 'payments',
    paymentInformation: 'Payment Information',
    areYouSure: 'Are you sure?',
    areYouSureSubtitle: 'Are you sure to cancel existing selected payment?',
    yes: 'yes',
    no: 'no',
    oops: 'Oops!',
    process: 'Process',
    youHavePendingPayment: 'You have an existing pending payment. Kindly process it first.',
    pendingPayment: 'Pending Payment',
    bankInformation: 'Bank Information',
    invoiceInformation: 'Invoice Information',
    howToPay: 'How to pay?',
    paymentConfirmation:
      'For faster payment confirmation, we would recommend you to upload recipe from your mobile banking application otherwise payment confirmation might be delayed',
    uploadReceipt: 'I have receipt. Upload Now',
    uploading: 'Uploading {{progress}}, please wait.',
    uploadComplete: 'Sucessfully submitted.',
    cancelPayment: 'Cancel This Payment',
    needHelp: 'Need help?',
    contactCustomerService: `Please contact our Successful Customers via {{customerServiceNumber}} if you have any questions.`,
    copied: 'copied to clipboard',
    vaNumber: 'VA Number',
    slogan: "Don't be late for your payment",
    payNow: 'pay now',
    downloadInvoiceSummary: 'Download invoice summary',
    invoiceDownloadSuccess: {
      title: 'Email Sent',
      message: 'Please check your email {{email}} to download invoice summary',
    },
    invoiceDownloadFailed: {
      title: 'No Invoice',
      message: 'You have not made any orders to ask for an invoice summary.',
    },
    creditSummary: {
      creditBalance: 'Credit balance',
      howToPay: 'How to pay?',
      availableCredit: 'Available',
      totalUsage: 'Total Usage',
      total: 'total credit',
      dueToday: 'due today',
      dueWeek: 'due next week',
      notDue: 'not yet due',
    },
    tabNames: {
      unpaid: 'Unpaid',
      due: 'due date',
      paid: 'paid',
      deposits: 'transaction',
    },
    noPaymentsAvailable: 'No payments made yet',
    showPaidInvoices: 'Show Paid Invoices',
    seeMore: 'see more',
    endOfPage: 'You have reached the end of this page',
    tabs: {
      catchMessage: 'No bills',
      dueDate: {
        catchMessage: 'there are no bills',
        dueToday: {
          title: 'due today',
          today: 'Today',
          desc: 'This note needs to be paid today',
        },
        dueWithinWeek: {
          title: 'next',
          desc: "It's better to get paid sooner rather than late",
        },
        notYetDue: {
          title: 'the rest of it',
          desc: 'No need to worry, this is just a reminder',
        },
      },
      paid: {
        notAvailable: 'Information not Available',
        message:
          'We have received your payment and updated your limit! We are currently still allocating your payment to outstanding invoices. You will be able to see the complete list below.',
        totalPayments: 'Total Payments',
        remainingPayment: '{{remainingAmount}} is still unallocated',
        fullyProcessed: 'Allocated',
        partiallyProcessed: '{{allocatedAmount}} is allocated',
        unallocatedInfo: 'Remaining deposit amount will be allocated for the next invoices',
        refund: 'Credit Note',
      },
    },
    voucher: {
      applied: 'Voucher worth {{amount}} applied as payment',
    },
    payAnyAmountInstruction: 'How much do you want to pay?',
    paymentBankInstruction: 'Select bank to use for payment',
    minimumPayAnyAmount: 'Amount must be at least {{amount}}',
    maximumPayAnyAmount: 'Amount must be less than {{amount}}',
    selectBankInstruction: 'Select a bank',
    awaitingPayment: 'Waiting for your Payment',
    paymentTransferBefore: 'Please make your transfer before',
    billNumber: 'Bill Number',
    copy: 'Copy',
  },
  orders: {
    title: 'orders',
    details: 'Order Details',
    distributor: 'Distributor',
    search: 'Search by product name, distributor, or invoice number',
    dueDate: 'Due Date',
    poNumber: 'PO Number',
    invoice: 'Invoice',
    poNumberCopy: 'PO Number copied',
    invoiceCopy: 'Invoice copied',
    invoiceNotAvailable: 'Not yet available',
    status: 'Status',
    totalPayment: 'Total Payment',
    itemChanged: 'Change in the Order',
    itemsChanged: 'Changes in the Order',
    tour: {
      orderTab: {
        clickHere: 'click here',
        title: 'Welcome to the all new Orders tab!',
        description:
          "We've taken the time to redesign your orders to make sure that we give you all the information you need to track your purchased products.",
        search: 'Search for orders',
        searchDesc: 'You can also view the orders via date and via distributor using our filters.',
        activities: 'Activities',
        activitiesDesc:
          'Want to see updates regarding your orders? With the new activity tab, you can now get the latest status of your orders.',
        end: 'We hope you enjoy the new order experience!',
        endDesc: 'If you have additional questions, please feel free to contact our Customer Success team via chat',
      },
      orderModal: {
        title: 'New Order Details',
        description:
          "This is the new order details experience. We've added more information that will help you manage your orders better.",
        status: 'Status',
        statusDesc: 'See a history of your order status and trace its progress.',
        documents: 'Documents',
        documentsDesc:
          'You can now start viewing your invoice documents with a single click. Simply select the document you want to view and we will display it for you.',
        products: 'Products',
        productsDesc:
          "Want to review the products in your order? With the new order experience, we've made it easier for you to get the information you need.",
      },
    },
    activityStatus: {
      title: {
        [ActivityStatus.PENDING]: 'We received Your order!',
        [ActivityStatus.PROCESSING]: 'We received Your order!',
        [ActivityStatus.ACCEPTED]: 'Your order has been accepted.',
        [ActivityStatus.ORDER_CHANGED]: 'Your order has changes',
        [ActivityStatus.DELAYED]: 'The PBF Closed for a while',
        [ActivityStatus.DISPATCHED]: 'Our courier is heading to You',
        [ActivityStatus.DELIVERED]: 'Order Delivered',
        [ActivityStatus.FULFILLED]: 'Order Fulfilled',
        [ActivityStatus.COMPLETED]: 'Order Completed',
        [ActivityStatus.CANCELLED]: 'Order Cancelled',
        [ActivityStatus.RETURNED]: 'Cancelled - Full Return',
        [ActivityStatus.PREKURSOR_REQUIRED]: 'Precursor Document Required',
        [ActivityStatus.PREKURSOR_APPROVED]: 'Precursor Document Approved',
        [ActivityStatus.PREKURSOR_CANCELLED]: 'Precursor Document Cancelled ',
        [ActivityStatus.PREKURSOR_REJECTED]: 'Precursor Document Rejected',
        [ActivityStatus.PREKURSOR_SUBMITTED]: 'Precursor Document Submitted',
        [ActivityStatus.PREKURSOR_RESUBMITTED]: 'Precursor Document Submitted',
      },
      description: {
        [ActivityStatus.PENDING]: 'Hang tight, We received your order. Tap here for more details.',
        [ActivityStatus.PROCESSING]: 'Hang tight, We received your order. Tap here for more details.',
        [ActivityStatus.ACCEPTED]: 'Order ID {{ po_number }} has been accepted. Tap here for more details.',
        [ActivityStatus.ORDER_CHANGED]: 'Order ID {{ po_number }} has changed. Tap here for more details.',
        [ActivityStatus.DELAYED]:
          'We’re sorry that order ID {{ po_number }} are delayed, We will proceed your order once open. Tap here for more details.',
        [ActivityStatus.DISPATCHED]: 'Your order ID {{ po_number }} is on it’s way! Tap here for more details.',
        [ActivityStatus.DELIVERED]: 'Yay, Your order {{ po_number }} has been delivered! Tap here for more details.',
        [ActivityStatus.FULFILLED]: 'Yay, Your order {{ po_number }} has been fulfilled! Tap here for more details.',
        [ActivityStatus.COMPLETED]: 'Yay, Your order {{ po_number }} has been completed! Tap here for more details.',
        [ActivityStatus.CANCELLED]: 'Your order {{ po_number }} has been cancelled! Tap here for more details.',
        [ActivityStatus.RETURNED]: 'Your order {{ po_number }} has been returned! Tap here for more details.',
        [ActivityStatus.PREKURSOR_REQUIRED]:
          'Your order {{ po_number }} need precursor document to continue the process. Tap here for more details.',
        [ActivityStatus.PREKURSOR_APPROVED]: 'Your precursor document has been approved. Tap here for more details.',
        [ActivityStatus.PREKURSOR_CANCELLED]: 'Your precursor document has been cancelled. Tap here for more details.',
        [ActivityStatus.PREKURSOR_REJECTED]: 'Your precursor document is rejected. Tap here for more details.',
        [ActivityStatus.PREKURSOR_SUBMITTED]:
          'Hang tight, we are validating your precursor document. Tap here for more details.',
        [ActivityStatus.PREKURSOR_RESUBMITTED]:
          'Hang tight, we are validating your precursor document. Tap here for more details.',
      },
    },
    orderStatus: {
      DEFAULT: '',
      [PharmacyOrderStatus.ALL_ORDERS]: 'all orders',
      [PharmacyOrderStatus.ORDER_RECEIVED]: 'confirmed',
      [PharmacyOrderStatus.WAITING_DOCUMENT]: 'On Approval Process',
      [PharmacyOrderStatus.PREPARING_GOODS]: 'processed',
      [PharmacyOrderStatus.DELAYED]: 'delayed',
      [PharmacyOrderStatus.OUT_OF_DELIVERY]: 'on delivery',
      [PharmacyOrderStatus.DELIVERED]: 'delivered',
      [PharmacyOrderStatus.CANCELLED]: 'canceled',
      [PharmacyOrderStatus.COMPLETED]: 'completed',
      [PharmacyOrderStatus.ORDER_CHANGED]: 'Order has changed',
      [PharmacyOrderStatus.CANCELLED_FULL_RETURN]: 'Fully Returned',
      [PharmacyOrderStatus.FULFILLED]: 'Fulfilled',
      [PharmacyOrderStatus.PAID]: 'Paid',
      [PharmacyOrderStatus.UNPAID]: 'Unpaid',
      returned: 'Returned',
      deliveredPartialReturn: 'Partially Returned',
    },
    orderPrecursorStatus: {
      all: 'All',
      [OrderPrecursorStatus.DOCUMENT_REQUIRED]: 'Document Required',
      [OrderPrecursorStatus.DOCUMENT_WAITING_APPROVAL]: 'Waiting for Document Approval',
      [OrderPrecursorStatus.DOCUMENT_REJECTED]: 'Rejected Documents',
      [OrderPrecursorStatus.DOCUMENT_CANCELLED]: 'Document Cancelled',
      [OrderPrecursorStatus.DOCUMENT_APPROVED]: 'Document Approved',
    },
    orderHistory: {
      DEFAULT: '',
      [PharmacyOrderStatus.ORDER_RECEIVED]: 'We received your order and will process as soon as possible',
      [PharmacyOrderStatus.PREPARING_GOODS]:
        "We are preparing your order. Will let you know when we're ready to deliver",
      [PharmacyOrderStatus.ORDER_CHANGED]: 'There is changes regarding your order',
      [PharmacyOrderStatus.OUT_OF_DELIVERY]: 'Your order its on your way with our courier. Please wait for us',
      [PharmacyOrderStatus.DELIVERED]: 'The order successfully delivered at your store.',
      [`${PharmacyOrderStatus.DELIVERED}By`]:
        'The order successfully delivered at your store. Received by {{ received_by }}',
      returned: 'The order has been returned',
      cancelled: 'The order was cancelled following your request. Tap here for details',
      date: 'Date',
    },
    orderStatusHistory: {
      DEFAULT: '',
      [OrderStatus.PENDING]: 'Confirmed',
      [OrderStatus.PROCESSING]: 'Order Process',
      [OrderStatus.ACCEPTED]: 'Order Process',
      [OrderStatus.ORDER_CHANGED]: 'Order Process',
      [OrderStatus.DISPATCHED]: 'Dispatching',
      [OrderStatus.RETURNED]: 'Returned',
      [LogisticDeliveryStatus.DELIVERY_COURIER_ASSIGNED]: 'Courier Assigned',
      [LogisticDeliveryStatus.DELIVERY_COURIER_DEPARTED]: 'Courier Departed',
      [LogisticDeliveryStatus.DELIVERY_PICKED_UP]: 'Parcel Picked Up',
      [LogisticDeliveryStatus.DELIVERY_ONGOING]: 'On Delivery',
      [LogisticDeliveryStatus.DELIVERY_COURIER_ARRIVED]: 'Courier Arrived',
      [LogisticDeliveryStatus.DELIVERY_DELIVERED]: 'Delivered',
      [LogisticDeliveryStatus.DELIVERY_DELAYED]: 'Delivery Delayed',
      [LogisticDeliveryStatus.DELIVERY_FAILED]: 'Failed to Deliver',
      [LogisticDeliveryStatus.DELIVERY_CANCELED]: 'Failed to Deliver',
      [OrderStatus.DELIVERED]: 'Delivered',
      [OrderStatus.CANCELLED]: 'Canceled',
    },
    orderStatusDetailHistory: {
      DEFAULT: '',
      [OrderStatus.PENDING]: 'Your order is packed and will be handover to delivery partner',
      [OrderStatus.PROCESSING]: 'Your order invoice already created',
      [OrderStatus.ACCEPTED]: 'Your order invoice already created',
      [OrderStatus.ORDER_CHANGED]: 'Your order has changes',
      [OrderStatus.DISPATCHED]: 'Your order is already prepared and ready to pickup by courier',
      [LogisticDeliveryStatus.DELIVERY_COURIER_ASSIGNED]:
        'Your order is already accepted by driver and will be delivered to you',
      [LogisticDeliveryStatus.DELIVERY_COURIER_DEPARTED]:
        'Our courier departed to the pick up point at <strong>Warehouse</strong>',
      [LogisticDeliveryStatus.DELIVERY_PICKED_UP]: 'Our parcel successfully picked up',
      [LogisticDeliveryStatus.DELIVERY_ONGOING]: 'Out for delivery! we will attempt to deliver your order',
      [LogisticDeliveryStatus.DELIVERY_COURIER_ARRIVED]:
        'Our courier has arrived and is waiting for you to receive the order',
      [LogisticDeliveryStatus.DELIVERY_DELIVERED]: 'Your order has been delivered',
      [`${LogisticDeliveryStatus.DELIVERY_DELIVERED}_received_by`]:
        'Your order has been delivered and received by <strong>{{received_by}}</strong>',
      [LogisticDeliveryStatus.DELIVERY_DELAYED]: 'We are sorry the delivery today was unsuccesful',
      [LogisticDeliveryStatus.DELIVERY_FAILED]: 'We are sorry the delivery today was unsuccesful',
      [LogisticDeliveryStatus.DELIVERY_CANCELED]: 'We are sorry the delivery today was unsuccesful',
      [`${LogisticDeliveryStatus.DELIVERY_FAILED}_description`]:
        'We are sorry the delivery today was unsuccesful<br/>Reason: {{description}}',
      [`${LogisticDeliveryStatus.DELIVERY_FAILED}_${LogisticDeliveryStatusDetail.PHARMACY_CLOSED}`]:
        'We are sorry the delivery today was unsuccesful<br/>Reason: pharmacy was close',
      [OrderStatus.DELIVERED]: 'Your order has been delivered',
      [`${OrderStatus.DELIVERED}_received_by`]:
        'Your order has been delivered and received by <strong>{{received_by}}</strong>',
      [OrderStatus.CANCELLED]: 'We are sorry the delivery today was cancel',
    },
    orderNote: {
      DEFAULT: '',
      [LogisticDeliveryStatusDetail.PHARMACY_CLOSED]:
        'The driver has arrived at the pharmacy, but the pharmacy is closed. The driver will return the next day to deliver the order.',
      [OrderStatus.DELIVERED]:
        'If any items were returned, we are currently processing and may take 24 hours to reflect the changes.',
    },
    orderStatusDeliveryProof: 'See Proof of Delivery',
    tabNames: {
      activity: 'activity',
      history: 'history',
    },
    DIRECT_PAYMENT: 'Direct Payment',
    CREDIT_LINE: 'Credit Line',
    paymentMethod: 'Payment Method',
    purchasedDate: 'Purchased Date',
    expectedDeliveryDate: 'Expected Delivery Date',
    toBeDetermined: 'To be determined',
    slaPrekursorWarningText: `Expected delivery date to be determined. Please upload the required document below`,
    slaUnpaidWarningText: `Expected delivery date to be determined. Please make payment first`,
    slaUnpaidWarningAction: `Make payment`,
    viewStatus: 'View Status',
    viewInvoice: 'View Invoice',
    invoiceNumber: 'Invoice Number',
    invoicePhoto: 'Invoice Photo',
    taxInvoice: 'Tax Invoice',
    viewTax: 'View Tax',
    deliveryProof: 'Delivery proof',
    viewProof: 'View proof',
    note: 'Note',
    orderInformation: 'Order Information',
    moreDetails: 'See Details',
    priceChanged: 'Price Changed',
    quantityChanged: 'Quantity Changed',
    unitPrice: 'Unit Price',
    quantity: 'Quantity',
    unit: 'Unit',
    units: 'Units',
    subTotal: 'Sub Total',
    discount: 'Discount',
    total: 'Total',
    paymentDetails: 'Payment Details',
    totalOrders: 'Total orders',
    tax: 'Tax',
    totalAmount: 'Total Purchases',
    totalAmountHistory: 'Total Order',
    amountAfterTax: 'Amount After Tax',
    paymentInformations: 'Payment Informations',
    paymentDate: 'Payment Date',
    needAssistanceForThisOrder: 'Need assistance for this order?',
    needAssistanceForReturn:
      'If any items were returned. We are currently processing and may take 24 hours to reflect the changes',
    yesIneedAssistance: 'Yes, I need assistance',
    needAssistance: 'Need Assistance?',
    needAssistanceOrderViaWhatsapp: 'Hello, can you assist me with Order # {{poNumber}}?',
    detailStatus: 'Status Detail',
    detailChanges: 'Changes Detail',
    orderChanged: 'this item has been updated',
    hna: 'Rp/Unit',
    outOfStock: 'Out of Stock',
    orderStatusTitle: 'Order Status',
    moreReturnedItems: 'See {{count}} more products',
    moreOrderItems: '{{count}} items left',
    minimizeOrderItems: 'Minimize product list',
    seeMoreDetails: 'see more details',
    return: {
      title: 'Returned Products',
      reason: 'Returned Reason',
    },
    delivered: {
      title: 'Delivered Products',
    },
    loyalty: {
      rewardPass: 'SwipeRx Reward Pass',
      rewardPointAdded: 'Reward Points',
      rewardPointDeducted: 'Deducted Points',
      rewardPointTotal: 'Total Reward Points',
      rewardPending: '(Pending)',
    },
    refund: {
      title: 'Refund Information',
      date: 'Credit Note Date',
      value: 'Total Credit Note Price',
      tax: 'Tax Credit Note',
      total: 'Total Credit Note',
    },
    creditMemo: {
      title: 'Credit Memo Information',
      no: 'Credit Memo No',
      see: 'See',
      document: 'Credit Memo Document',
      download: 'Download',
      documentOnProgress: 'Credit memo document is still being processed.',
    },
    checkout: 'Check Out',
    prekursorTooltipTour: {
      activityFilter: 'You can find pending prekursor document here',
      activityCard: 'Click this card to upload prekursor document',
    },
    alkes: {
      title: 'Medical Devices',
    },
    regular: {
      title: 'Regular',
    },
    spDocumentNonPrekursor: {
      info: 'Information',
      downloadSPSample: 'Download The Example of SP Document',
    },
    prekursor: {
      title: {
        required: 'Precursor Document Required',
        submitted: 'Waiting for Document Approval',
        resubmitted: 'Waiting for Document Approval',
        approved: 'Precursor Document Approved',
        rejected: 'Precursor Document Rejected',
        cancelled: 'Precursor Document Cancelled',
      },
      add: 'Add Prekursor Document',
      uploadSuccess: {
        title: 'Yay! your precursor document image uploaded succesfully',
        info: 'You can check status of this order in Activities tab',
        action: 'OKAY',
      },
      uploadFailed: {
        title: 'Oops! The upload of your precursor document failed',
        info: 'failed file:',
        action: 'RETRY',
      },
      uploadApprovedFailed: {
        title: 'Document already approved, can not update document',
        info: 'failed file:',
        action: 'RETRY',
      },
      prekursorTooltipTour: {
        preUpload: 'Click here to upload Prekursor document',
        postUpload: 'Tap here to preview or edit Prekursor document',
        editUpload: 'Tap here to edit Prekursor document image',
      },
      requiredLabel: 'Prekursor Document Needed',
      required: 'Prekursor Document Needed',
      submitted: "Waiting Document's Approval",
      resubmitted: "Waiting Document's Approval",
      approved: 'Document Approved',
      rejected: 'Document Rejected',
      cancelled: 'Document Cancelled',
      instruction: 'Instruction',
      downloadSPSample: 'Download The Example of SP Document',
      rejected_reason: 'Rejected Reason',
      reasons: {
        document_unclear: 'Submitted Image Is Not Clear',
        photo_unclear: 'Photo Blurred',
        precursor_format_invalid: 'SP Format Invalid',
        address_pbf_invalid: 'PBF Name & Address Invalid',
        no_sipnap: 'No SIPNAP Number',
        sipnap_not_match: 'Invalid SIPNAP Data',
        pharmacy_data_incomplete: 'Pharmacy Document Not Complete',
        specimen_not_updated: 'Specimen Not Updated',
        pharmacy_data_invalid: 'Pharmacy Data Invalid',
        apj_data_invalid: 'Pharmacy APJ Data Invalid',
        signature_blank: 'APJ Signature Blank',
        signature_not_match: 'Invalid APJ Signature',
        signature_dry: 'APJ Signature Not From Ink',
        stamp_blank: "Pharmacy's Stamp Blank",
        stamp_not_match: "Pharmacy's Stamp Invalid",
        stamp_dry: "Pharmacy's Stamp Not From Ink",
        wrong_core_molecules: 'Incorrect Core Molecules',
        wrong_product_name: 'Incorrect Product Name',
        wrong_quantity: 'Incorrect Product Quantity',
      },
      fileSizeValidationError: 'File size cannot be larger than {{size}}MB',
      fileTypeValidationError: 'File type must be image',
      generalValidationError: 'Something went wrong',
      sendSPViaEmail:
        'We send sample precursor document to your email, please check your email that registered on our system',
    },
    orderInfoDelivered: 'delivered Products',
    orderInfoReturned: 'returned products',
    letsRateYourOrder: 'Let’s rate your order experience',
    howDidYouLikeYourOrder: 'How did you like the product and overall order experience from <0>{{distributor}}<0>?',
    tapTheStarToRate: 'Tap the stars (1 is terrible, 5 is awesome)',
    tellUsMore: 'Tell us more!',
    optional: 'Optional',
    submit: 'Submit',
    whatCouldBeImproved: 'What could be improved?',
    enjoyedLetUsKnow: 'Enjoyed? Let us know...',
    moreProducts: '+{{ n }} more products',
    oneItem: '1 product',
    writeYourFeedback: 'Write Review',
    yourOpinionMatter: 'Your opinion matter to us! Please take a moment to let us know your thoughts.',
    starRating: {
      terrible: 'Terrible',
      poor: 'Poor',
      ok: 'Okay',
      good: 'Good',
      awesome: 'Awesome',
    },
    gladYouEnjoyed: {
      header: "We're glad you enjoyed it!",
      text: 'We appreciate your feedback. Thank you for sharing your order experience with us!',
    },
    sorryForYourInconvenience: {
      header: 'Oops! sorry for your inconvenience',
      text: 'We appreciate your feedback. We are promise to improve our services. ',
    },
    done: 'done',
  },
  orderConfirmed: {
    title: 'Order Confirmation',
    hooray: 'Hooray!',
    ordersSuccessfullySubmitted:
      'Your orders have been successfully submitted to our distributors. Please see the list of orders below.',
    prepareSignedPurchaseOrder: 'Please Prepare your Signed Purchase Order',
    fulfillOrders:
      'Our distributors can only fulfill your order if you have a printed and duly signed purchase order. Please make sure to present this to our distributors prior to delivery.',
    orderSummary: 'Order Summary',
    totalOrderAmount: 'Total Order Amount',
    eVat: 'VAT',
    totalAfterVat: 'Total Amount (Tax may be included)',
    seeDetails: 'See Details',
    orderNumber: 'Order Number',
    netAmount: 'Net Amount',
    ordered: 'Ordered on',
    reserved: 'Ordered on',
    amountAfterTax: 'Total Amount (Tax may be included)',
    rateYourOrderExperience: 'How would you rate your order experience?',
    needHelp: 'Need help?',
    ourTeamMoreThanHappyToAssist:
      'Our team is more than happy to answer any of your inquiries regarding your transactions and payments.',
    pleaseContact: 'Please contact our',
    customerSuccess: 'Customer Success',
    teamViaWhatsApp: `team via {{phoneNumber}}`,
    checkout: 'Check Out',
    orderedOn: 'Ordered on',
    prekursorFlag: 'Prekursor Docs Required',
    alkesFlag: 'Medical Devices',
    regularFlag: 'Regular',
    prekursorTooltipTour: {
      orderSummary: 'Click here to upload document',
    },
    spDocumentNonPrekursor: {
      info: 'Information',
      downloadSPSample: 'Download The Example of SP Document',
    },
    voucher: {
      chooseVoucher: 'Apply a voucher',
      voucherApplied: 'Voucher worth {{amount}} applied as payment',
    },
  },
  promotedPage: {
    noPromoted: 'Promos will be available soon. You may check again later.',
    packaging: 'Packaging',
    quantity: 'Quantity',
    addProduct: 'Add product',
    addToCart: 'Add to cart',
    pbfDeals: 'Best PBF Deals',
    showMore: 'Show more',
    greatDeals: 'See more great deals',
    pageEnd: 'You have reached the end of this page. Check back later for more.',
    buyNow: 'Buy now',
    noProducts,
    noProductsInArea: `The item you are looking for is not available. Please find other products or contact the Customer Support Team for further assistance.`,
    recentlyPurchased: 'Recently Purchased',
    cartModal,
  },
  productPage: {
    removeItemConfirmation: 'Would you like to remove this product from your basket?',
    add: 'Add',
    similarProducts: 'Similar Products',
    yes: 'Yes',
    no: 'No',
  },
  accountPage: {
    myAccount: 'My Account',
    license: {
      yourActiveLicenses: 'Your Active Licenses',
      document: 'Document',
      validUntil: 'Valid until {{valid_until}}',
      expiredOn: 'Expired on {{expired_on}}',
      valid: 'Valid',
      verified: 'Verified',
      unverified: 'Unverified',
      contactUs: 'Contact Us',
      willExpireMsg:
        'The license will expire on {{valid_until}}. Please submit your renewed license to continue using the verified account.',
      expiredMsg: 'The license is expired. Please submit your renewed license to continue using the verified account.',
      helpUpgrade: 'Hello, I want to upgrade my account, please help.',
      ...pharmacyTierInfo,
    },
    available: {
      creditBalance: 'Credit Balance',
      availableCredit: 'Available Credit',
      usedCredit: 'Used Credit',
      totalCredit: 'Total Credit',
    },
    balance: {
      outstandingBal: 'Outstanding Balance',
      viewOrders: 'View Orders',
      paymentMethod: 'Payment Method',
      viewDetails: 'View Details',
      howToPay: 'Payment Method',
      bank: 'Bank',
      accountNo: 'Account Number',
      name: 'Name',
      needHelp: 'Need help?',
      helpMore: `For more information, please contact our Customer Success team via {{callCenterNumber}}.`,
      noBankDetail: 'No bank details',
    },
    virtualAccount: {
      paymentChannels: 'Payment Channels',
      setAsDefault: 'Set As Default',
      default: 'Default',
      copy: 'Copy',
      copied: 'Copied',
      learnHowToPay: 'Learn How To Pay',
      selectMethod: 'Bank Information',
      instruction: 'Transfer any amount to your preferred bank',
      needToDo: `Here’s what you need to do`,
      transferToAnyOfTheFollowingA: 'Transfer',
      transferToAnyOfTheFollowingB: 'on {{due_date}} to any of the following payment channels:',
      paymentDetails: 'Your total amount of {{value}} will be auto-filled in your banking-app.',
    },
    help: {
      needHelp: 'Need help?',
      customerSuccess: 'Customer Success',
      message: 'Please contact {{customer_success}} via {{number}} if you have any questions.',
      message1: 'Please contact our Customer Success if you have any other questions/concerns:',
      message2: 'WA Business : Chat on WhatsApp with SwipeRx Shopping',
      message3: 'Phone : {{callCenterNumber}}',
      message4: 'Monday - Friday / 08.00 - 21.00',
      message5: 'Saturday - Sunday / 08.00-17.00',
      message6: 'National Holidays / 08.00-17.00',
    },
    swipeApp: {
      swiperx: 'Other features',
      news: 'News',
      cpd: 'Education',
    },
    helpSupport: {
      homepage: 'Set Procurement as Home page',
      howToAddProduct: 'How do I add products?',
      howToOrder: 'How do I create an order?',
      help: 'Help & Support',
      faq: 'Frequently Asked Questions',
      policy: 'Privacy Policy',
      contact: 'Contact Us',
      terms: 'Terms and Conditions',
      exit: 'Exit',
    },
    tour: {
      welcomeTitle: 'Welcome to your Basket!',
      welcomeContent: 'Let’s check out the new features.',
      detail: '',
      detailContent: 'Here is where you can find your account details.',
      credit: '',
      creditContent: 'Check out your available credit in this section.',
      due: 'Do you want to find how much you need to pay?',
      dueContent: 'This section provides information about your outstanding payments.',
      payment: 'Do you need to know how to pay?',
      paymentContent: 'This section provides information about your payment method.',
      swipe: 'Easy-Access to SwipeRx',
      swipeContent: 'Click on the links to proceed to the newsfeed or education section.',
      homePage: 'Procurement is now your home page for SwipeRx',
      homePageContent: 'To change this setting, simply update the item below.',
      howToAddProductsContent: 'How do I add products',
      howToCreateOrderContent: 'How do I create an order?',
      exit: '',
      exitContent: 'Exit the app by clicking on this button.',
    },
    benefits: {
      title: 'Account Benefits',
      currentAccount: 'Current account',
      unverified: 'unverified',
      verified: 'verified',
      verifyYourAccountUnlock: 'Verify your account to unlock more benefits',
      typeOfProductsEligible: 'Type of Products Eligible to Purchase',
      withoutCustomLogo: 'Product without special logo',
      withOTCMarkingLogo: 'Products with OTC marking logo',
      greenSymbolWithBlackCircle: 'Green symbol with black outline circle',
      otcWithoutPrescription:
        "Over-the-counter drugs are sold in the market and can be purchased without a doctor's prescription.",
      blueSymbolWithBlackCircle: 'Blue symbol with black outline circle',
      otcWithPrescription:
        "Over-the-counter drugs are sold in the market and can be purchased without a doctor's prescription.",
      treeSymbol: 'Tree symbol',
      medicineMadeNatural: 'Traditional medicine or herbal medicine made from natural ingredients',
      withRedLogo: 'Product with red logo',
      letterKsymbolRedCircle: '‘K’ Symbol in red circle',
      strongDrugsWithPrescription: "Strong drugs that can only be obtained with a doctor's prescription",
      productPrecursor: 'Product Prekursor',
      multiplePaymentOptions: 'Multiple Payment Options',
      secureAndConvenientPayment: 'Secure and convenient payment',
      directPaymentOption:
        'Using direct payment option you can pay after placing an order using a bank VA of your choice through our secured and protected payment system.',
      unlockCreditLimit: 'Unlock credit limit',
      creditLimitBasedEligibility: 'Chance to receive credit limit of up to Rp500.000.000 based on your eligibility.',
      licenseRequirements: 'License Requirements',
      submitDocuments: 'Verify your account by submitting the following documents',
      speciment: 'Speciment',
      activeLicense: 'Lisensi Aktif',
      siaDocument: 'SIA Document',
      sipaDocument: 'SIPA Document',
      sipnapDocument: 'SIPNAP Document',
      ownerId: 'KTP Pemilik',
      pharmacistId: 'KTP Apoteker',
      npwpOwnerId: 'NPWP Owner',
      selfieWithID: 'Selfie dengan KTP',
      verifyYourAccount: 'verify your account',
      verifyYourAccountChat1:
        'Hi CS, I want to upgrade my pharmacy account from unverified to verified, can you please help?',
      verifyYourAccountChat2:
        'Hi CS, I want to upgrade my pharmacy account to purchase prekursor products, can you please help?',
      licenseRequirements2: 'License Requirements',
      licenseTitle2: 'Lisensi Aktif',
    },
    creditLimitUpgradeRequest: {
      cardMessage: 'Want to increase your credit limit?\nRequest now.',
      pendingCardMessage: 'Click to view your request status.',
      blockedMessage:
        "Your account is blocked. There are {{dpdInvoiceCount}} bills\n that haven't been paid for up to {{dpd}} days.\nPay off immediately so you can shop again.",
      suspendMessage: 'Your account has been temporarily suspended',
    },
  },
  favoritesPage: {
    favorites: 'favorites',
    pageEnd: 'You have reached the end of this page.',
    empty: `You don't have a favorite product yet.`,
    removedFromFavoriteProduct: 'Favorite product have been deleted',
  },
  productRemindersPage: {
    message: 'The products will be automatically removed from this page 7 days after they become available.',
    noProducts: `You don't have a product reminder yet.`,
  },
  productList: {
    pageEnd: 'You have reached the end of this page.',
    empty: 'The product list is empty.',
  },
  productCard: {
    upTo: 'Up to {{discount}}% discount',
    addedToFavoriteProduct: 'Product is added to favorite.',
    removedFromFavoriteProduct: 'Product is removed from favorite.',
    prekursorTooltipTour: {
      productCard: 'This product required prekursor document',
      productCardTitle: 'Prekursor Icon',
    },
    maxDailyQtyLimit: 'MAX {{value}}/DAY',
    maxMonthlyQtyLimit: 'MAX {{value}}/MONTH',
  },
  wishlistPage: {
    wishlist: 'Wishlist',
    reminders: 'Reminders',
    comingSoon: 'Coming Soon',
    favorites: 'Favorites',
  },
  loyalty: {
    today: 'Today',
    noData: 'No data',
    point: 'Point',
    points: 'Points',
    member: 'Member',
    status: {
      [LoyaltyHistoryStatus.ALL]: 'All',
      [LoyaltyHistoryStatus.VERIFIED]: 'Active',
      [LoyaltyHistoryStatus.BONUS]: 'Bonus',
      [LoyaltyHistoryStatus.PENDING]: 'Pending',
      [LoyaltyHistoryStatus.DEDUCTED]: 'Deducted',
      [LoyaltyHistoryStatus.EXPIRED]: 'Expired',
      [LoyaltyHistoryStatus.EXPIRING_SOON]: 'Expiring Soon',
      [LoyaltyHistoryStatus.REDEEMED]: 'Redeemed',
    },
    cardDescription: {
      [LoyaltyHistoryStatus.VERIFIED]: 'Purchased from PO Number',
      [LoyaltyHistoryStatus.BONUS]: 'Purchased from PO Number',
      [LoyaltyHistoryStatus.PENDING]: 'Purchased from PO Number',
      [LoyaltyHistoryStatus.DEDUCTED]: 'Purchased from PO Number',
      [LoyaltyHistoryStatus.EXPIRED]: 'Purchased from PO Number',
      [LoyaltyHistoryStatus.EXPIRING_SOON]: 'Purchased from PO Number',
      [LoyaltyHistoryStatus.REDEEMED]: 'Purchased',
    },
    loyaltyPointDescriptionTitle: 'What are loyalty points?',
    loyaltyPointDescriptionMore: 'Learn More',
    retry: {
      message: 'Something went wrong',
      button: 'Try again',
    },
    rewards: {
      all: 'all',
      title: 'Rewards',
      myRewards: 'My Rewards',
      hotRewards: 'Hot Rewards',
      redeemNow: 'Redeem Now',
      howToUse: 'How to Use',
      termsOfUse: 'Terms of Use',
      redeemedOn: 'Redeemed on',
      redeemDialoag: {
        title: 'You’re All Set',
        description: 'You just redeemed',
        description2: 'Enjoy!',
        close: 'Close',
      },
      errorMessage: {
        insufficientPoints: 'You need {{points}} more points in order to redeem this reward.',
        dpd: 'Oh no, you can’t redeem the reward because you have outstanding payment(s) for more than {{dpd}} days. (DPD). Please pay your invoices soon.',
      },
      filter: {
        pointsRequired: 'Points Required',
        filterByPoints: 'Filter by Points',
        minimumPoints: 'Minimum Points',
        maximumPoints: 'Maximum Points',
        filterByVendor: 'Filter by Vendor',
        applyFilter: 'Terapkan Filter',
        vendor: 'Vendor',
        vendorName: 'Vendor Name',
        search: 'Search',
      },
      noRedeemData: 'no recently claimed rewards',
    },
    landing: {
      title: 'Loyalty Program Dashboard',
      lastUpdate: 'Last update',
      breakdown: {
        [LoyaltyHistoryStatus.VERIFIED]: 'Active Points',
        [LoyaltyHistoryStatus.PENDING]: 'Pending Points',
        [LoyaltyHistoryStatus.REDEEMED]: 'Redeemed Points',
        [LoyaltyHistoryStatus.DEDUCTED]: 'Deducted Points',
        [LoyaltyHistoryStatus.EXPIRING_SOON]: 'Expiring Soon',
      },
      seeAllPointsHistory: 'See All Points History',
      whatDoPointsMean: 'What do these points mean?',
      pointMeaning: {
        [LoyaltyHistoryStatus.VERIFIED]: {
          title: 'Active Points',
          description:
            'These are the points when your orders are delivered and fulfilled. It takes up to 7 days convert your pending points to verified points.  These points are redeemable.',
        },
        [LoyaltyHistoryStatus.PENDING]: {
          title: 'Pending Points',
          description:
            'These are the points you get immediately when you order via SwipeRx Belanja app and NOT redeemable yet. These points will remain pending until the orders are delivered or fulfilled.',
        },
        [LoyaltyHistoryStatus.REDEEMED]: {
          title: 'Redeemed Points',
          description:
            'These are the points we deduct from your verified points after you redeem the points for rewards.',
        },
        [LoyaltyHistoryStatus.BONUS]: {
          title: 'Bonus Points',
          description:
            'These are the points that earned every time you make an order on a specially marked product or through our program with bonus points',
        },
        [LoyaltyHistoryStatus.DEDUCTED]: {
          title: 'Deducted Points',
          description:
            "These are the points that we will reduce when you return product(s) or cancel any order that we have already fulfilled. This deducted points will based on the return product(s) 'value.",
        },
        [LoyaltyHistoryStatus.EXPIRING_SOON]: {
          title: 'Expiring Soon Points',
          description:
            'These are the points that will be expired in the next 30 days. Once they expired, they can no longer be redeemed. All points will expire after 6 months, starting from the time your points got activated.',
        },
      },
      seeAllRewards: 'See All Rewards',
      allRewards: 'All Rewards',
      seeMore: 'See More',
      benefitAndTiers: 'Benefits & Tiers',
      howToEarn: 'How to earn',
      faq: 'Frequently Asked Questions',
      tnc: 'Terms and Conditions',
      cycle: "You're in the {month} month of your {cycle} cycle",
      viewMembership: 'View Membership',
      claimedReward: 'You have {{reward}} claimed reward(s)',
      pointsDescription: 'Earn points on every purchase and redeem it at SwipeRX Rewards here!',
      pointsExpiringSoon1: 'You have {{points}}',
      pointsExpiringSoon2: 'expiring soon',
      howToGetPoints: {
        title: 'How To Get Points?',
        orderFromSwiperx: {
          title: 'Order from SwipeRx app',
          description: 'You will get Rp 50 for every Rp 10.000 fully spent',
          action: 'Start Earning',
        },
        reachHigherTier: {
          title: 'Reach higher tiers to get Swiper Bonus',
          description: 'Get bonus points when you hit Gold, Platinum and Titanium tier',
        },
        participateInPromotion: {
          title: 'Participate in promotions',
          description: 'Get additional points by joining monthly campaigns',
        },
        getMoreAdditionalBenefits: {
          title: 'Get more additional benefits',
          description: 'COMING SOON',
        },
      },
      mostPopularRewards: 'Most Popular Rewards',
      missions: {
        title: 'Missions',
        description: 'Complete these missions to unlock exclusive rewards!',
        seeAllLink: 'See All',
        seeAllButton: 'SEE ALL {{total}} ACTIVE MISSIONS',
        viewCompletedButton: 'View your completed missions',
      },
    },
    history: {
      title: 'History',
      pointReceived: 'Points Received',
      pointUsed: 'Points Deducted',
      rollbackMessage: 'Failed to redeem {{rewardName}}. Reason: {{reason}}. RefId: {{referenceId}}',
      rollbackReturnMessage: 'Rollback return {{memo}}. Reason: {{reason}}',
    },
    membership: {
      title: 'My Membership',
      tierDescription: 'You’ve earned {{points}} points this cycle.',
      retainLevel: 'Earn {{points}} more points by {{date}} to maintain your {{level}} tier',
      pointsDescription: 'Earn points on every purchase and redeem it at SwipeRX Rewards here!',
      breakdown: {
        [LoyaltyHistoryStatus.VERIFIED]: 'Active Points',
        [LoyaltyHistoryStatus.PENDING]: 'Pending Points',
        [LoyaltyHistoryStatus.REDEEMED]: 'Redeemed Points',
        [LoyaltyHistoryStatus.DEDUCTED]: 'Deducted Points',
        [LoyaltyHistoryStatus.EXPIRING_SOON]: 'Expiring Soon',
        [LoyaltyHistoryStatus.BONUS]: 'Bonus Points',
      },
      redeem: 'Redeem',
      pointSummary: 'Point Summary',
      lastUpdate: 'Last update',
      seeAllPointsHistory: 'See All Points History',
      pointDefinition: 'Definition of Points',
      whatIsExpirationDate: 'What is expiration date?',
      expirationDateDetails:
        'Points that will expire in 30 days and after 30 days all points earned cannot be redeemed. All points will expire after 6 months since becoming active points.',
      redeemPointNow: 'Redeem Point Now',
      benefitsTitle: 'Membership Benefits',
      benefitsBtn: 'LEARN MORE',
      benefitsLevel: {
        [LoyaltyTierName.BRONZE]: `bronze member`,
        [LoyaltyTierName.SILVER]: ` silver member`,
        [LoyaltyTierName.GOLD]: `gold member`,
        [LoyaltyTierName.PLATINUM]: `platinum member`,
        [LoyaltyTierName.TITANIUM]: `titanium member`,
        [LoyaltyTierName.SPECIALCASHBACK]: `special_cashback member`,
        [LoyaltyTierName.EXTRACASHBACK]: `extra_cashback member`,
        [LoyaltyTierName.IPECREGISTRANT]: `ipec_registrant member`,
        [LoyaltyTierName.VIP]: `vip member`,
      },
    },
    redeemFailed: {
      DEFAULT: 'Please contact our Customer Support',
      OL501: 'No coupons left',
      OL502: 'Not enough points',
      OL503: 'Please wait, your redemption is in progress',
      MP13: 'Customer number is blocked',
      MP14: 'Incorrect destination number',
      MP16: 'Number does not match with operator',
      MP106: 'Product is temporarily out of service',
      MP203: 'Number is too long',
      MP206: 'Destination number has been blocked',
    },
    redeemDialog: {
      title: 'Info Diperlukan',
      description: 'In order for us to process your redeem, please provide the following information.',
      privacyPolicyLabel: 'By clicking submit, you agree to our',
      privacyPolicyText: 'Privacy Policy',
      submitText: 'Submit',
      errorMessage: 'Please input the valid value',
    },
    redeemStatus: {
      [LoyaltyRewardRedeemDeliveryStatus.ORDERED]: 'Ordered',
      [LoyaltyRewardRedeemDeliveryStatus.DISPATCHED]: 'On Delivery',
      [LoyaltyRewardRedeemDeliveryStatus.DELIVERED]: 'Delivered',
      [LoyaltyRewardRedeemDeliveryStatus.CANCELED]: 'Cancelled',
      [LoyaltyRewardRedeemDeliveryStatus.EXPIRED]: 'Expired',
    },
    orderPotentialPoints: 'This order has potential to earn points',
    orderIncreasePoints: 'Increase your order to get points',
    orderEarnPoint: 'Earn',
  },
  loyaltyMission: {
    viewListProduct: 'view list of products',
    viewDetails: 'view details',
    promoProduct: '*Promo limited to selected products only',

    tab: {
      [LoyaltyMissionStatus.ACTIVE]: 'ACTIVE',
      [LoyaltyMissionStatus.COMPLETED]: 'COMPLETED',
      [LoyaltyMissionStatus.EXPIRED]: 'EXPIRED',
    },
    progress: {
      [LoyaltyMissionActivityTypeCode.GMV]: 'Reach {{value}} GMV',
      [LoyaltyMissionActivityTypeCode.ACTIVE_DAYS]: 'Reach {{value}} Monthly Active Days',
      [LoyaltyMissionActivityTypeCode.OPEN_APP]: 'Open app {{value}} times',
      [LoyaltyMissionActivityTypeCode.REQUEST_PRODUCT]: 'Request Product {{value}} times',
      [LoyaltyMissionActivityTypeCode.NOTIFY_ME]: 'Click product availability button {{value}} times',
      [LoyaltyMissionActivityTypeCode.REDEEM_POINTS]: 'Redeem {{value}} Rewards',
      [LoyaltyMissionActivityTypeCode.SKU_MIX]: 'Number of Product Variation: {{value}}',
    },
    pendingProgress: 'A total of {{value}} are still in the validation process',
    targetReachedLabel: 'Target Reached',
    targetReachedInfo: {
      title: 'How Target Reached works?',
      description:
        'Achievements are calculated based on completed transactions. You will see your achievements when the invoice is printed.',
    },
    date: {
      [LoyaltyMissionStatus.ACTIVE]: 'Ends on {{value}}',
      [LoyaltyMissionStatus.EXPIRING]: 'Ending soon on {{value}}',
      [LoyaltyMissionStatus.EXPIRED]: 'Expired on {{value}}',
      [LoyaltyMissionStatus.COMPLETED]: 'Completed on {{value}}',
    },
    rewardPoint: {
      [LoyaltyMissionStatus.ACTIVE]: 'Earn {{value}}',
      [LoyaltyMissionStatus.EXPIRING]: 'Earn {{value}}',
      [LoyaltyMissionStatus.EXPIRED]: 'Earn {{value}}',
      [LoyaltyMissionStatus.COMPLETED]: 'You won {{value}}',
    },
    rewardGift: {
      [LoyaltyMissionStatus.ACTIVE]: 'Get a {{value}}',
      [LoyaltyMissionStatus.EXPIRING]: 'Get a {{value}}',
      [LoyaltyMissionStatus.EXPIRED]: 'Get a {{value}}',
      [LoyaltyMissionStatus.COMPLETED]: 'You won a {{value}}',
    },
    tnc: 'Terms & Conditions',
    noData: 'There are no missions to display',
    noActiveTitle: 'Stay Tuned!',
    noActiveDescription: 'New mission is coming soon',
    completedDialog: {
      title: 'Congratulations!',
      description: 'You completed Level {{level}} of the {{mission}}! You have received',
      rewardPoints: '{{value}} points',
      viewNextLevel: 'View next level',
      viewCompletedMission: 'View completed mission',
    },
  },
  loyaltyRegistrationPage: {
    title: "Let's Get Started",
    description: `To discover our programs, <br />benefits and rewards in one place. <br /><br />Or still need more information <br />about our Loyalty Programs?<br />`,
    form: {
      pharmacyLabel: 'Pharmacy Name',
      pharmacyPlaceholder: 'Your registered pharmacy name',
      pharmacyInvalid: 'Invalid pharmacy name',
      phoneLabel: 'Phone Number',
      phonePlaceholder: 'Number that can be contacted',
      phoneInvalid: 'Invalid phone number',
      emailLabel: 'Email Address',
      emailPlaceholder: 'Email address that can be contacted',
      emailInvalid: 'Invalid email',
      agree: {
        toSwiperx: 'I agree to SwipeRx',
        termsOfUse: 'Terms of Use',
        and: 'and',
        privacyPolicy: 'Privacy Policy',
        invalid: 'Please check',
      },
    },
    readHere: 'Read Here',
    joinNow: 'Join Now',
    alreadyJoined: 'Oops! it looks like your pharmacy has already joined the program by using email',
    contactSupport:
      'If you feel there is an error, or want to change your email regarding loyalty program. Please contact our Customer Support.',
    contactUs: 'Contact Us',
    join_now: 'Join Now',
    learn_more: 'Learn More',
    next: 'Next',
    skip: 'Skip',
    walkthrough: {
      benefit: {
        title: 'Many Benefits',
        content: 'More shopping, more benefits',
      },
      extra_point: {
        title: 'Extra Point',
        content: 'Get bonus points from our promos',
      },
    },
  },
  loyaltyBanner: {
    member: 'Member',
    point: 'Point',
    mission: 'Mission',
    join_now: 'Join Now',
    learn_more: 'Learn More',
    next: 'Next',
    skip: 'Skip',
    walkthrough: {
      benefit: {
        title: 'Many Benefits',
        content: 'More shopping, more benefits',
      },
      extra_point: {
        title: 'Extra Point',
        content: 'Get bonus points from our promos',
      },
    },
    viewRewards: 'View Rewards',
    viewPlayNow: 'Play Now',
    active_mission: 'Active Missions',
    activeMissionsMessage1: 'You have ',
    activeMissionsMessage2: '{{totalActiveMissions}} active missions!',
    activeMissionsMessage3: '',
    pointsDescription: 'Earn points on every purchase and redeem it here!',
  },
  productRequest: {
    question: "Can't find what you are looking for?",
    title: 'TELL US HERE',
    inputLabel: 'Product Name',
    inputPlaceholder: "Type your product's name...",
    buttonSubmit: 'Let SwipeRx knows!',
    thankYou: 'Thank you for your suggestion!',
    oops: 'Something went wrong. Please try again in a few minutes.',
    lengthLessThan3: 'Product name must be more than 3 characters...',
    pageEnd: 'You have reached the end of this page.',
    empty: `You haven't suggested any products yet.`,
  },
  directPayment: {
    copied: 'Transaction ID copied to clipboard',
    close: 'Close',
    billCreateError: 'Failed to create bill. Try again later.',
    billCreateErrorRetry: 'Failed to create bill. Retrying ... ',
    headerTitle: 'Payment Information',
    timerLabel: 'Waiting for payment',
    instructions: 'Your bill number is',
    saveQRCode: 'Take a screenshot of this QR code for your reference',
    totalAmount: 'Total Amount',
    billCode: 'Bill Code',
    footerInstructionTitle: 'How to pay',
    footerInstructionDetails: {
      step1: 'Take screenshot of this QR code',
      step2: 'Open your bank’s mobile application',
      step3: 'Upload this QR code into the bank application (QR code will automatically populate total amount)',
      step4: 'Ensure that your bank account has enough funds in order to make a successfull payment',
      step5:
        'Once you have transferred money through your banking app, we will prepare your order for deliver. Please reach out to @SwipeRx_TH with any questions',
    },
    paidBillNotification: {
      title: 'Yay! your payment was successful!',
      content:
        'Your bill number {{billReference}} is has been place. We are excited for you to received your order within 24 hours.',
      action: 'Check your order here',
    },
    paymentFullNotification: {
      title: 'Yay! your payment was successful!',
      content: 'Your order has been placed and will be processed soon!',
      action: 'Check your order here',
    },
    headerPaymentFullTitle: 'Thank You!',
    headerPaymentFullMessage: 'Your order will be processed soon!',
  },
  directPaymentList: {
    amount: 'Amount',
    poNumber: 'Order ID',
    checkout: 'Checkout',
    totalAmount: 'Total Amount',
    voucherApplied: 'Voucher Applied',
  },
  directPaymentModal: {
    title: 'Order Summary',
    checkout: 'Checkout',
    netAmount: 'Net Amount',
    eVat: 'VAT',
    ordered: 'Ordered on',
    reserved: 'Ordered on',
    amountAfterTax: 'Total amount (tax may be included)',
    viewOrder: 'Order details',
    orderNumber: 'Order Number',
    hooray: 'hooray',
    ordersSuccessfullySubmitted:
      'Your orders have been successfully submitted to our distributors. Please see the list of orders below.',
    prepareSignedPurchaseOrder: 'Please Prepare your Signed Purchase Order',
    fulfillOrders:
      'Our distributors can only fulfill your order if you have a printed and duly signed purchase order. Please make sure to present this to our distributors prior to delivery.',
    orderedOn: 'Ordered on',
    voucher: {
      chooseVoucher: 'Apply a voucher',
      voucherApplied: '{{amount}} Discount Applied!',
    },
    prekursorTooltipTour: {
      orderSummary: 'Click here to upload document',
    },
  },
  productDetailPage: {
    availablePromo: 'Available Promo',
    promoNotAvailable: 'Promotion is empty',
    detail: 'Detail',
    description: 'Description',
    composition: 'Composition',
    coreMolecule: 'Core Molecule',
    category: 'Category',
    atcClassification: 'ATC Classification',
    manufacturer: 'Manufacturer',
    shipmentTitle: 'Shipping',
    returnTitle: 'Return Policy',
    nearestExpiryDateTitle: 'Nearest Exp. Date',
    shipmentContent1:
      '<ul><li>For area Jabodetabek, Bandung Raya and Surabaya, Sidoarjo, Mojokerto, Gresik, Malang, Pasuruan, the delivery will be processed in Sameday*</li><li>For area Jawa Barat except for Bandung Raya and area Jawa Timur except for Surabaya, the delivery will be processed on the next day up to 2 days*</li><li>For area Jawa Tengah and surrounding areas, the delivery will be processed on the next day up to 5 days*</li><li>For area Bali and surrounding areas, the delivery will be processed on the next day up to 2 days*</li></ul><p>*<em>To get more details for the estimated time of delivery in your area, please check </em><a href="https://belanja.swiperxapp.com/estimasi-waktu-pengiriman-barang/" rel="noopener noreferrer" target="_blank"><em>on this link</em></a></p><p><em>*You also can check the estimation time of delivery in the menu “activity” by selecting the order number</em></p>',
    shipmentContent2: 'As per company basis.',
    addedToFavoriteProduct: 'Product is added to favorite.',
    removedFromFavoriteProduct: 'Product is removed from favorite.',
    addedToCart: 'item(s) has been added to the cart',
    addedToCartWithMaxQty:
      'As you have already made purchases of this product, you are eligible to buy only {{ maxPurchaseQty }} more unit(s) today.',
    seeCart: 'see',
    discount: 'Discount',
    minimum: 'Buy min.',
    totalPrice: 'Total Price',
    addToCart: 'Add To Cart',
    outOfStock: 'Sorry, this product is sold out',
    maxPurchase: 'Due to high demand, you can order only up to {{value}} unit(s) today.',
    maxPurchaseRemaining:
      'As you have already made purchases of this product, you are eligible to buy only {{ value }} more unit(s) today.',
    maxPurchaseReached: 'Maximum quantity reached, you can order only up to {{value}} unit(s) today.',
    maxPurchaseMonthlyReached:
      'You will reach {{value}} maximum monthly limit for this product after checkout. To do more purchasing after checkout, you may purchase it again next month or reach out to our Customer Service team to get further service',
    maxPurchaseMonthlyThresholdReached:
      'You almost hit your {{value}} monthly limit for this product. If you need to purchase more than monthly limit, before proceeding please reach out to our Customer Service team to get further service.',
    notify: 'Remind Me',
    unnotify: 'Cancel Reminder',
    similarProducts: 'Similar Products',
    loyaltyPointTitle: 'This product can earn you',
    loyaltyPointPoints: 'points!',
    prekursorDocumentRequired: `This product required prekursor documents.`,
    instruction: `Instruction`,
    prekursorTooltipTour: {
      productDetail: 'You can find an example of prekursor document here',
    },
    maxDailyQtyLimit: 'MAX {{value}}/DAY',
    maxMonthlyQtyLimit: 'MAX {{value}}/MONTH',
    productUnavailable: 'Product is not available',
    ...pharmacyTierInfo,
    ...tabsProductDetail,
  },
  homepage: {
    greetings: 'Hi {{pharmacyName}}, welcome!',
    categories: 'Categories',
    recentlyViewed: recentlyViewedPageTitle,
    productInfo: 'Original\nProducts',
    competitivePrice: 'Best Offer',
    sameDayDelivery: 'Order\nAnytime',
    more: 'MORE',
  },
  bannerListPage: {
    pageTitle: 'All Promo',
  },
  productListingPage: {
    cartModal,
    all: 'all',
    noProducts,
    marketingNoProducts:
      'This product {{productName}} does not exist in the playlist {{playlistName}}. Try to search this product here',
    search: productListingSearch,
    searchPlaceholder: 'Search 3000+ products here',
  },
  recentlyViewedPage: {
    title: recentlyViewedPageTitle,
    pageEnd: 'You have reached the end of this page.',
    search: productListingSearch,
  },
  widget: {
    seeAll: 'See All',
    seeAllCategories: 'See All Product+',
  },
  maintenancePage: {
    title: `Under Maintenance`,
    subtitle: `The page you're looking for is currently under maintenance and will be back very soon`,
    back: `Back`,
    reloadMessage: 'We have new updates waiting for you. Please re-open the app.',
    close: 'Close',
  },
  purchase_order_document_requirement: {
    pendingPrekursorDocument: `You have pending prekursor document to upload`,
    prekursorTooltipTour: {
      banner: 'Click here to upload Prekursor Document',
      bannerTitle: 'Pending Prekursor Document',
    },
  },

  changePasswordPage: {
    title:
      'To complete your registration and access SwipeRx Belanja, please type in your new password and confirm the new password',
    newPass: 'New Password',
    enterPass: 'Enter Password',
    confirmPass: 'Confirm Password',
    confirmPass2: 'Confirm Password',
    submitPass: 'Change Password',
    successTitle: 'Password updated!',
    successHeader: 'Your password has been updated successfully.',
    successFooter: 'Use your new password to login',
    LoginSwipe: 'Login To SwipeRx',
    oldPass: 'Old Password',
    passwordReq:
      '*Please input a minimum of 8 characters. We suggest to include at least 1 capital letter, 1 small letter, 1 number, and 1 special character to make your password secure (i.e. PTAbc12!#)',
    generalError: 'You can change your password through the SwipeRx application by clicking on "Forgot your password"',
    validationEnterPass: 'Please enter your password',
    validationMinPass: 'Password must be at least 8 characters',
    ValidationPassReq:
      'A minimum of 8 characters password should contain a combination of uppercase and lowercase letter, number and special characters are required',
    ValidationPassMatch: '"New Password" and "Confirm Password" must match',
  },
  voucher: {
    vouchers: 'Vouchers',
    enterYourVoucherCodeHere: 'Enter Your Voucher Code Here',
    search: 'Search',
    apply: 'Apply',
    applyNow: 'Apply Now',
    nextTime: 'next time',
    limitedOffers: 'Limited Offers',
    validDate: 'Valid Date',
    free: 'Free',
    oops: 'Oops!',
    close: 'Close',
    loadMore: 'Load More',
    USAGE_LIMIT_ERROR: 'Voucher usage has already exceeded the limit',
    LIMIT_PER_CUSTOMER_ERROR: 'Voucher usage has already exceeded the limit',
    QUANTITY_RANGE_ERROR: "Purchase order quantity doesn't meet the voucher condition",
    AMOUNT_RANGE_ERROR: "Purchase order amount doesn't meet the voucher condition",
    SELECTED_PHARMACIES_ERROR: "Your pharmacy isn't eligible for this voucher",
    SELECTED_DISTRIBUTORS_ERROR: "The distributor isn't eligible for this voucher",
    SELECTED_DISTRIBUTOR_PRODUCTS_ERROR: "The distributor product isn't eligible for this voucher",
    SELECTED_PRODUCTS_ERROR: "The product isn't eligible for this voucher",
    PAYMENT_METHOD_ERROR: "The payment methods isn't eligible for this voucher",
    OPEN_LOYALTY_ENROLLED_ERROR: 'Your pharmacy has not been enrolled to Loyalty program',
    OPEN_LOYALTY_POINT_SUFFICIENT_ERROR: 'Your loyalty point is insufficient',
    OPEN_LOYALTY_VOUCHER_REGISTERED_ERROR: 'Voucher is invalid',
    INVALID_VOUCHER_ERROR: 'Voucher is invalid',
    ANY_PENDING_PAYMENT_ERROR: `Voucher can not be used while there is pending payment. Please finish the payment first.`,
    INVALID_VOUCHER_RULES_ERROR: 'Voucher is invalid',
    VOUCHER_NOT_FOUND_ERROR: 'Voucher not found',
  },
  unverifiedPharmacy: pharmacyTierInfo,
  snackbar: {
    unverifiedPharmacy: pharmacyTierInfo,
    maxQuantityLimit: {
      maxPurchaseDaily: 'Sorry! due to high demand, you can order only up to {{maxUnit}} unit(s) today.',
      maxPurchaseMonthly: 'Sorry! As per internal policy, you can order only up to {{maxUnit}} unit(s) for the month',
    },
    ok: 'Ok',
  },
  depositDetail: {
    depositInformation: 'Deposit information',
    refundInformation: 'Credit Note information',
    totalDeposit: 'Total Deposit',
    totalRefund: 'Total Credit Note',
    depositAllocatedTo: 'Deposit Allocated To',
    refundAllocatedTo: 'Credit Note Allocated To',
    full: 'Paid',
    partial: 'Partially paid',
    unallocated: 'Unallocated',
    invoiceNo: 'Invoice No.',
    orderNo: 'Order No.',
    needHelp: 'Need help?',
    contactCustomerService: `Please contact our Successful Customers via <0>{{customerServiceNumber}}</0> if you have any questions.`,
    showMore: 'Show more',
    creditMemoInformation: 'Credit Memo Information',
    creditNoteNumber: 'Credit Memo No.',
    creditMemoDocument: 'Credit Memo Document',
    creditMemoDocumentInProgress: 'Credit memo document is still being processed.',
    see: 'see',
    download: 'download',
    creditMemoNumberCopied: 'Credit Memo Number copied',
    invoiceNumberCopied: 'Invoice Number copied',
    totalAmount: 'Total Amount',
    outstandingAmount: 'Outstanding Amount',
    noInvoiceToDisplay: 'No available invoice to display',
    goBack: 'Go Back',
    depositWillBeAllocated: 'Your deposit will be automatically allocated to the available invoices soon',
    refundWillBeAllocated: 'Your refund will be automatically allocated to the available invoices soon',
  },
  similarProductListPage: {
    pageTitle: 'Similar Products',
    noProducts,
  },
  addToCartNotif: {
    title: 'Added to basket!',
    message: 'You have added {{productName}} to your basket.',
    action: 'GO TO BASKET',
  },
  availability: {
    timer: {
      maxPurchaseDaily:
        "You've reached the maximum unit(s) allowed for the purchase of the product. <0>It cannot be purchased again until tomorrow.</0>",
      maxPurchaseMonthly:
        'You have reached the monthly maximum limit for this product. You may purchase it again next month.',
    },
  },
  noAvailableProducts: {
    title: 'The product is not available',
    subtitle: 'Please look for other products or contact the Customer Success Team for further assistance.',
    fuzzyTitle: 'Oops! No results for {{query}}',
    button: 'SEARCH FOR OTHER PRODUCTS',
    relatesTo: 'Related searches to {{query}}',
  },
};

export default lang;
