import { ListMeta, LoyaltyHistoryGroup } from 'services';
import { DataObject } from 'types';

export type LoyaltyProgramCalculatedPoint = {
  calculating?: boolean;
  points: number;
};

export type LoyaltyURLs = {
  term_of_use: string;
  privacy_policy: string;
  frequently_asked_question: string;
  tier_and_benefit: string;
  how_to_earn: string;
  tier_and_benefit_levels?: Record<LoyaltyTierName, string>;
};

export type LoyaltyCycle = {
  cycleNumber: number;
  cycleMonth: number;
};

export enum LoyaltyTierName {
  BRONZE = 'bronze',
  SILVER = 'silver',
  GOLD = 'gold',
  PLATINUM = 'platinum',
  TITANIUM = 'titanium',
  SPECIALCASHBACK = 'special_cashback',
  EXTRACASHBACK = 'extra_cashback',
  IPECREGISTRANT = 'ipec_registrant',
  VIP = 'vip',
}

export type LoyaltyProgramInfo = {
  claimedRewardsCount: number;
  pendingPoints: number;
  firstName: string;
  lastName: string;
  customerId: string;
  points: number;
  p2pPoints: number;
  totalEarnedPoints: number;
  usedPoints: number;
  expiredPoints: number;
  lockedPoints: number;
  blockedPoints: number;
  potentialPoints: number;
  potentialPointsDisabled: boolean;
  potentialProductPoints?: LoyaltyPotentialProductPoints | undefined;
  level: string;
  levelName: string;
  levelConditionValue: number;
  nextLevel: string;
  nextLevelName: string;
  nextLevelConditionValue: number;
  transactionsAmountToNextLevelWithoutDeliveryCosts: number;
  transactionsAmountWithoutDeliveryCosts: number;
  averageTransactionsAmount: string;
  transactionsCount: number;
  transactionsAmount: number;
  currency: string;
  sumOfExchangedPointsToday: number;
  sumOfCurrencySuccessfullyRedeemed: number;
  pointsExpiringNextMonth: number;
  pointsExpiringBreakdown: DataObject;
  storeCode: string;
  firstTransactionDate: string;
  lastTransactionDate: string;
  levelAchievementDate: string;
  createdAt: string;
  invitationToken: string;
  deductedPoints: LoyaltyProgramCalculatedPoint;
  redeemedPoints: LoyaltyProgramCalculatedPoint;
  expiringPoints: LoyaltyProgramCalculatedPoint;
  bonusPoints: LoyaltyProgramCalculatedPoint;
  lastFetchedAt?: Date;
  urls: LoyaltyURLs;
  cycle: LoyaltyCycle;
};

export type LoyaltyRewardRedeemInput = {
  name: string;
  label: string;
  type: number;
  validation_regex: string;
  validation_message?: string;
};

export type LoyaltyRewardRedeem = {
  redeemVendor: string;
  redeemInfo: Array<{ key: string; value: string }>;
  redeemInputFields: LoyaltyRewardRedeemInput[];
  redeemPrivacyPolicyUrl: string;
};

export type LoyaltyReward = {
  id: number;
  name: string;
  brandName: string;
  points: number;
  imageUrl: string;
  isRedeemed?: boolean;
  redeemedAt?: string;
  purchasedRewardStatus?: string;
  description?: string;
  condition?: string[];
  usage?: string[];
  imageUrls?: string[];
  isDpdAcceptable: boolean;
  isActivePointsSufficient: boolean;
  customerDpd: number;
  customerPoints: number;
  rewardCode: string | null;
  redeem?: LoyaltyRewardRedeem;
  redeemRewardStatus?: LoyaltyRedeemRewardStatus;
  redeemRewardDeliveryStatus?: LoyaltyRewardRedeemDeliveryStatus;
} | null;

export type LoyaltyRegistration = {
  pharmacyName: string;
  phoneNumber: string;
  emailAddress: string;
} | null;

export enum LoyaltyRewardType {
  HOT_REWARDS = 'hot-rewards',
  MY_REWARDS = 'my-rewards',
}
export enum LoyaltyRedeemRewardStatus {
  PROCESSING = 'processing',
  DISPATCHED = 'dispatched',
  SUCCESS = 'success',
  FAILED = 'failed',
}
export enum LoyaltyRewardRedeemDeliveryStatus {
  ORDERED = 'ordered',
  DISPATCHED = 'dispatched',
  DELIVERED = 'delivered',
  CANCELED = 'canceled',
  SUCCEEDED = 'succeeded',
  EXPIRED = 'expired',
}
export enum LoyaltyRewardPreviewType {
  MOST_POPULAR = 'preview-most-popular-rewards',
  LATEST = 'preview-latest-rewards',
  ALL = 'preview-all-rewards',
  MY_REWARDS = 'preview-my-rewards',
}

export const MAPPING_REDEEM_DELIVERY_STATUS = {
  [LoyaltyRedeemRewardStatus.PROCESSING]: LoyaltyRewardRedeemDeliveryStatus.ORDERED,
  [LoyaltyRedeemRewardStatus.DISPATCHED]: LoyaltyRewardRedeemDeliveryStatus.DISPATCHED,
  [LoyaltyRedeemRewardStatus.SUCCESS]: LoyaltyRewardRedeemDeliveryStatus.DELIVERED,
  [LoyaltyRedeemRewardStatus.FAILED]: LoyaltyRewardRedeemDeliveryStatus.CANCELED,
};

/** loyalty mission interface */

export enum LoyaltyMissionActivityTypeCode {
  GMV = 'GMV',
  ACTIVE_DAYS = 'ACTIVE_DAYS',
  DPD = 'DPD',
  OPEN_APP = 'OPEN_APP',
  REQUEST_PRODUCT = 'REQUEST_PRODUCT',
  NOTIFY_ME = 'NOTIFY_ME',
  REDEEM_POINTS = 'REDEEM_POINTS',
  SKU_MIX = 'SKU_MIX',
}

export enum LoyaltyMissionStatus {
  ACTIVE = 'active',
  COMPLETED = 'completed',
  EXPIRED = 'expired',
  EXPIRING = 'expiring',
}

export interface LoyaltyMissionLevelProgress {
  activityId?: number;
  activityCode: string;
  progress: number;
  value: number;
  pendingProgress?: number;
  pendingPercentage?: number;
}

export enum LoyaltyMissionLevelRewardType {
  POINTS = 'points',
  GIFT = 'gift',
}

export interface LoyaltyMissionLevel {
  id?: number;
  level: number;
  percentage: number;
  progress: LoyaltyMissionLevelProgress[];
  isStarted: boolean;
  isCompleted: boolean;
  startedAt?: string;
  completedAt?: string;
  productMarkertingId?: number;
  productMarkertingUrl?: string;
  rewardType: LoyaltyMissionLevelRewardType;
  rewardPoint?: number;
  rewardGift?: string;
}

export interface LoyaltyMission {
  id: number;
  name: string;
  imageIcon?: string;
  imageBanner?: string;
  isExpired?: boolean;
  isExpiringSoon?: boolean;
  startDate: string;
  endDate: string;
  createdAt: string;
  completedAt?: string;
  status: LoyaltyMissionStatus;
  level: LoyaltyMissionLevel;
}

export interface LoyaltyMissions {
  error: string | null;
  loading: boolean;
  data: LoyaltyMission[];
  meta: ListMeta;
  status?: LoyaltyMissionStatus;
}

export interface LoyaltyMissionInfo {
  id: number;
  name: string;
  imageIcon?: string;
  imageBanner?: string;
  isExpiringSoon?: boolean;
  isExpired?: boolean;
  startDate: string;
  endDate: string;
  completedAt?: string;
  status: LoyaltyMissionStatus;
  description: string;
  tnc: string[];
  levels: LoyaltyMissionLevel[];
}

export interface LoyaltyMissionCompleted {
  trackId: number;
  missionId: number;
  missionCompleted: boolean;
  name: string;
  level: number;
  rewardType: LoyaltyMissionLevelRewardType;
  rewardPoints: number;
  rewardGift: string;
}
export interface LoyaltyProgramStore {
  loading: boolean;
  registered: boolean | null;
  enabled: boolean | null;
  history: {
    loading: boolean;
    data: LoyaltyHistoryGroup[];
    dataLength: number;
    meta: ListMeta;
  };
  info: LoyaltyProgramInfo;
  rewards: {
    data: LoyaltyReward[];
    meta: ListMeta;
    type: LoyaltyRewardType | null;
    loading: boolean;
    queries: LoyaltyRewardsQueryParams;
  };
  rewardInfo: LoyaltyReward | null;
  rewardInfoLoading: boolean;
  rewardRedeem: LoyaltyReward | null;
  error: string | null;
  registration: LoyaltyRegistration;
  redeemFailed: string | null;
  redeemLoading: boolean;
  rewardCategory: LoyaltyRewardCategory;
  displayPendingPoint: boolean;
  displayMission: boolean;
  displayWelcomeMission: boolean;
  previewMostPopularRewards: LoyaltyPreviewRewards;
  previewLatestRewards: LoyaltyPreviewRewards;
  previewAllRewards: LoyaltyPreviewRewards;
  previewMyRewards: LoyaltyPreviewRewards;
  pointsToMaintain: LoyaltyPoints;
  pointsToUpgrade: LoyaltyPoints;
  pointsInProgress: LoyaltyPointsInProgress;
  missions: LoyaltyMissions;
  missionInfo: {
    error?: string;
    loading: boolean;
    data?: LoyaltyMissionInfo;
  };
  missionsBanner: string[] | undefined;
  totalActiveMissions: number | undefined;
  completedMissions: LoyaltyMissionCompleted[];
}

export interface LoyaltyDetailCache {
  pendingPoints: number;
  activePoints: number;
  tier?: string;
}

export interface LoyaltyPotentialProductPointsDetail {
  points: number;
  rewards: string[];
}

export interface LoyaltyPotentialProductPoints {
  [sku: string]: LoyaltyPotentialProductPointsDetail;
}

export interface LoyaltyPotentialPointItem {
  id: number;
  name: string;
  sku: { code: string };
  grossValue: number;
  quantity: number;
  category: string;
}

export interface LoyaltyPotentialPointsPayload {
  amount: number;
  tax_rate: number;
  items: LoyaltyPotentialPointItem[];
}

export interface LoyaltyPotentialPoints {
  points: number;
  is_disabled: boolean;
  details: LoyaltyPotentialProductPoints;
}

export interface LoyaltyPreviewRewards {
  loading: boolean;
  data: LoyaltyReward[];
  error?: string | undefined;
}

export interface LoyaltyRewardsQueryParams {
  random?: boolean;
  min_point?: number;
  max_point?: number;
  vendor?: string;
  name?: string;
  sort_by?: string;
  order_by?: string;
}

export interface LoyaltyRewardsVendor {
  name: string;
}

export interface LoyaltyRewardCategoryItem {
  id: string;
  name: string;
}
export interface LoyaltyRewardCategory {
  data: LoyaltyRewardCategoryItem[];
  selected: LoyaltyRewardCategoryItem | null;
  loading: boolean;
}

export interface LoyaltyProductEarnPoint {
  points: number;
  rewards: string[];
}

export interface LoyaltyPoints {
  error?: string | undefined;
  requesting?: boolean;
  loading: boolean;
  data: {
    tier: LoyaltyTierName;
    totalPoints: number | null;
    pointsNeeded: number;
    pointsLeft: number;
    endOfCycleDate: Date;
    cycle: number;
  };
}

export interface LoyaltyPointsInProgress extends LoyaltyPoints {
  type: 'upgrade' | 'maintain';
  isMaxTier: boolean;
}

export interface LoyaltyPointsResponse {
  tier: LoyaltyTierName;
  pointsNeeded: number;
  pointsLeft: number;
  totalPoints: number;
  deadlineDate: string;
  cycle: number;
}
